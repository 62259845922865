// import client from '@client'
import client from '@/services/real'

class QuizService {
  constructor() {
    this.type = ''
  }

  fetchQuizzes = async () => {
    const quizzes = await client.fetchQuizzes()
    return quizzes
  }

  fetchQuizById = async (quizId) => {
    const quiz = await client.fetchQuizById(quizId)
    return quiz
  }

  fetchQuizBySlug = async (quizSlug) => {
    const quiz = await client.fetchQuizBySlug(quizSlug)
    return quiz
  }

  generateResponses(quiz, userResponses) {
    return quiz.questions.map((q, qIndex) => {
      // points per question
      const numberOfCorrectResponses = q.responses.filter((r) => r.correct).length
      const hasMultipleAnswers = numberOfCorrectResponses > 1
      const maxScorePerQuestion = 1
      const pointsPerCorrectAnswer = hasMultipleAnswers
        ? maxScorePerQuestion / numberOfCorrectResponses
        : maxScorePerQuestion
      const penaltyPerWrongAnswer = hasMultipleAnswers
        ? -(maxScorePerQuestion / numberOfCorrectResponses)
        : 0

      // merged response
      return {
        question: q.question.query,
        hasMultipleAnswers,
        maxScore: maxScorePerQuestion,
        options: q.responses.map((r, rIndex) => {
          return {
            answer: r.text,
            correct: r.correct,
            pointsCorrect: r.correct ? pointsPerCorrectAnswer : penaltyPerWrongAnswer,
            pointsIncorrect: r.correct ? 0 : 0,
            // did the user select this response?
            userResponse: hasMultipleAnswers
              ? userResponses[qIndex].includes(rIndex)
              : userResponses[qIndex] === rIndex
          }
        })
      }
    })
  }

  calculateScore(quizResponses) {
    return quizResponses.reduce((runningScore, response) => {
      const questionScore = response.options.reduce((responseScore, option) => {
        // only scores of user selections are included (vs. non-selections)
        const optionScore = option.userResponse ? option.pointsCorrect : option.pointsIncorrect
        return responseScore + optionScore
      }, 0)
      const adjustedQuestionScore = questionScore < 0 ? 0 : questionScore
      return runningScore + adjustedQuestionScore
    }, 0)
  }

  postResults = async (quizResults) => {
    const response = await client.postResults(quizResults)
    return response
  }

  calculateAggregateScore(quiz, userResponses) {
    let initialScore = {
      numericScore: 0,
      percentageScore: 0,
      quizTotalCorrectAnswers: 0,
      userTotalCorrectAnswers: 0,
      userTotalIncorrectAnswers: 0,
      userTotalPoints: 0
    }

    /* assumptions:
     * 1)single choice: maxScore = 1, pointsCorrect = 1, pointsIncorrect = 0
     * 2)mutiple choice: maxScore = 1, pointsCorrect = 1, pointsIncorrect = 1
     * 3)minScore >= 0
     */

    // FIXME: add pointsCorrect / subtract pointsIncorrect to / from score
    // FIXME: normalize to maxScore of question

    return userResponses.reduce((score, answer, i) => {
      if (Array.isArray(answer)) {
        // multiple correct answers exist for this question

        // count the number of correct answers for this question
        const questionCorrectAnswers = quiz.questions[i].responses.filter(
          (response) => response.correct
        ).length
        score.quizTotalCorrectAnswers += questionCorrectAnswers

        // count the number of selected *correct* answers for this question
        const userCorrectAnswers = answer.filter(
          (response) => quiz.questions[i].responses[response].correct
        ).length
        score.userTotalCorrectAnswers += userCorrectAnswers

        // count the number of selected *incorrect* answers for this question
        const userIncorrectAnswers = answer.filter(
          (response) => quiz.questions[i].responses[response].correct === false
        ).length
        score.userTotalIncorrectAnswers += userIncorrectAnswers

        // accumulate the score (negative score is a 0)
        const actualPoints = userCorrectAnswers - userIncorrectAnswers
        const points = actualPoints >= 0 ? actualPoints : 0
        score.userTotalPoints += points
        score.numericScore += points / questionCorrectAnswers
      } else {
        // only a single correct answer exists
        score.quizTotalCorrectAnswers += 1
        if (answer != null && quiz.questions[i].responses[answer].correct) {
          score.userTotalCorrectAnswers += 1
          score.userTotalPoints += 1
          score.numericScore += 1
        } else {
          score.userTotalIncorrectAnswers += 1
          score.numericScore += 0
        }
      }

      score.percentageScore = Math.round(
        (score.userTotalPoints * 100) / score.quizTotalCorrectAnswers
      )

      return score
    }, initialScore)
  }
}

export default QuizService
export const quizService = new QuizService()
