import Vue from 'vue'
import Vuex from 'vuex'
import { adService } from '@/services/adService'

Vue.use(Vuex)

const initialState = {
  ads: [],
  loaded: false,
  promise: null
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {
    getAdsByZone(state) {
      return (zone) => state.ads.filter((ad) => zone === '*' || ad.zones.includes(zone))
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      state.ads = [...newState.ads]
    },

    /* specific mutations */

    setAds(state, ads) {
      state.ads = [...ads]
    },

    setLoaded(state, loaded) {
      state.loaded = loaded
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* fetch actions */

    async fetchAds({ state, commit }, { locale, keywords }) {
      try {
        const ads = await adService.fetchAds({
          locale,
          keywords,
          zone: ''
        })
        commit('setAds', ads)
      } catch (error) {
        console.warn('[adStore]: Using cached ads.', error)
      } finally {
        commit('setLoaded', true)
      }
      return state.ads
    },

    clearAds({ commit }) {
      commit('setLoaded', false)
      commit('setAds', [])
    }
  }
}
