<template>
  <div ref="container" class="c-book-player c-container" :class="{ 'c-fullscreen': isFullscreen }">
    <SlideCarousel
      v-if="isLoaded"
      class="c-carousel"
      :slides="bookSlides"
      :thumbs="bookThumbs"
      :landscape="isLandscapeMode"
      :background-colour="isFullscreen ? 'black' : 'sheet'"
    >
      <template #slide="props">
        <div v-if="areSlidesImages" class="c-image-wrapper">
          <img class="c-image" :src="props.src" />
        </div>
        <div
          v-else
          class="c-iframe-wrapper d-flex align-center justify-center"
          :class="[bookOrientation === 'portrait' ? 'c-portrait' : 'c-landscape']"
        >
          <iframe
            class="c-iframe"
            allowfullscreen
            :src="props.src"
            :width="`${contentWidth}px`"
            :height="`${contentHeight}px`"
          />
        </div>
      </template>
      <template #thumb="props">
        <v-img class="c-thumb" contain height="100%" width="100%" :src="props.src" />
      </template>
    </SlideCarousel>
  </div>
</template>

<script>
import SlideCarousel from './components/SlideCarousel'
import { contentService } from '@/services/contentService.js'

export default {
  name: 'BookPlayer',

  components: {
    SlideCarousel
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      book: {},
      isLandscapeMode: true,
      isLoaded: false,
      unavailableImage: require('@/assets/images/placeholders/unavailable.png')
    }
  },

  computed: {
    /* fetch context */

    bookId() {
      return this.content.contentEntity?.id
    },

    bookPath() {
      return this.content.book
        ? ''
        : this.content.contentURL?.substring(0, this.content.contentURL?.lastIndexOf('/')) || ''
    },

    bookSlug() {
      return this.content.contentURL.split('/').at(-1)
    },

    /* dynamic settings */

    implicitThumbs() {
      return this.bookSlides.map((slide) => {
        const names = slide.split('/')
        const name = names[names.length - 1]
        return `${this.bookPath}/thumbs/${name.split('.')[0]}.png`
      })
    },

    isFullscreen() {
      return this.fullscreen
    },

    contentHeight() {
      return this.book?.orientation === 'portrait' ? 1024 : 768
    },

    contentWidth() {
      return this.book?.orientation === 'portrait' ? 768 : 1024
    },

    deviceWidth() {
      return this.$vuetify.breakpoint.name
    },

    /* image arrays */

    areSlidesImages() {
      return (
        this.book.images?.base?.images?.length > 0 || this.book.images?.base?.pImages?.length > 0
      )
    },

    areSlidesPages() {
      return !this.areSlidesImages
    },

    areSlidesResponsive() {
      return (
        this.book?.images?.xs?.images?.length > 0 ||
        this.book?.images?.xs?.pImages?.length > 0 ||
        this.book?.images?.sm?.images?.length > 0 ||
        this.book?.images?.sm?.pImages?.length > 0 ||
        this.book?.images?.md?.images?.length > 0 ||
        this.book?.images?.md?.pImages?.length > 0 ||
        this.book?.images?.lg?.images?.length > 0 ||
        this.book?.images?.lg?.pImages?.length > 0 ||
        this.book?.images?.xl?.images?.length > 0 ||
        this.book?.images?.xl?.pImages?.length > 0
      )
    },

    defaultLandscapeImages() {
      return this.book.images?.base?.images?.length > 0 ? this.book.images.base.images : null
    },

    defaultPortraitImages() {
      return this.book.images?.base?.pImages?.length > 0 ? this.book.images.base.pImages : null
    },

    landscapeImages() {
      console.debug('this.book.images=', this.book.images)
      const xsLandscapeImages =
        this.book.images?.xs?.images?.length > 0 ? this.book.images.xs.images : null
      const smLandscapeImages =
        this.book.images?.sm?.images?.length > 0 ? this.book.images.sm.images : null
      const mdLandscapeImages =
        this.book.images?.md?.images?.length > 0 ? this.book.images.md.images : null
      const lgLandscapeImages =
        this.book.images?.lg?.images?.length > 0 ? this.book.images.lg.images : null
      const xlLandscapeImages =
        this.book.images?.xl?.images?.length > 0 ? this.book.images.xl.images : null

      return {
        xs:
          xsLandscapeImages ||
          smLandscapeImages ||
          mdLandscapeImages ||
          lgLandscapeImages ||
          xlLandscapeImages,
        sm: smLandscapeImages || mdLandscapeImages || lgLandscapeImages || xlLandscapeImages,
        md: mdLandscapeImages || lgLandscapeImages || xlLandscapeImages,
        lg: lgLandscapeImages || xlLandscapeImages,
        xl: xlLandscapeImages
      }
    },

    portraitImages() {
      const xsPortraitImages =
        this.book.images?.xs?.pImages?.length > 0 ? this.book.images.xs.pImages : null
      const smPortraitImages =
        this.book.images?.sm?.pImages?.length > 0 ? this.book.images.sm.pImages : null
      const mdPortraitImages =
        this.book.images?.md?.pImages?.length > 0 ? this.book.images.md.pImages : null
      const lgPortraitImages =
        this.book.images?.lg?.pImages?.length > 0 ? this.book.images.lg.pImages : null
      const xlPortraitImages =
        this.book.images?.xl?.pImages?.length > 0 ? this.book.images.xl.pImages : null

      return {
        xs:
          xsPortraitImages ||
          smPortraitImages ||
          mdPortraitImages ||
          lgPortraitImages ||
          xlPortraitImages,
        sm: smPortraitImages || mdPortraitImages || lgPortraitImages || xlPortraitImages,
        md: mdPortraitImages || lgPortraitImages || xlPortraitImages,
        lg: lgPortraitImages || xlPortraitImages,
        xl: xlPortraitImages
      }
    },

    /* book output */

    bookOrientation() {
      return this.book?.orientation || 'landscape'
    },

    bookImages() {
      /*
      console.debug('[BookPlayer]: deviceWidth=', this.deviceWidth)
      console.debug('[BookPlayer]: landscapeImagesDictionary=', this.landscapeImages)
      console.debug('[BookPlayer]: portraitImagesDictionary=', this.portraitImages)
      console.debug('[BookPlayer]: defaultLandscapeImages=', this.defaultLandscapeImages)
      console.debug('[BookPlayer]: defaultPortraitImages=', this.defaultPortraitImages)
      console.debug('[BookPlayer]: deviceLandscapeImages=', this.landscapeImages[this.deviceWidth])
      console.debug('[BookPlayer]: devicePortraitImages=', this.portraitImages[this.deviceWidth])
      */
      // the image set depends on the orientation and device width
      return this.isLandscapeMode
        ? this.landscapeImages[this.deviceWidth] ||
            this.defaultLandscapeImages ||
            this.defaultPortraitImages ||
            []
        : this.portraitImages[this.deviceWidth] ||
            this.defaultPortraitImages ||
            this.defaultLandscapeImages ||
            []
    },

    bookSlides() {
      if (this.areSlidesImages) {
        // uses image tags for slides and original images as thumbs
        // (supports device specific images)
        console.debug('[BookPlayer]: bookImages=', this.bookImages)
        return this.makeAbsolute(this.bookImages)
      }

      if (this.book.pages?.length > 0 || this.book.contents?.length > 0) {
        // uses iframes for slides and assumes a naming convention for thumbs
        const pages = this.book.pages?.length > 0 ? this.book.pages : this.book.contents
        console.debug('[BookPlayer]: bookPages=', pages)
        return this.makeAbsolute(pages)
      }

      return [this.unavailableImage]
    },

    bookThumbs() {
      // thumbs are optional for page based books
      // (but only show thumbs if there is more than one slide)
      if (this.areSlidesImages) {
        return this.bookSlides.length > 1 ? this.bookSlides : []
      } else {
        return this.bookSlides.length > 1 && this.book.thumbs?.length > 1
          ? this.makeAbsolute(this.book.thumbs)
          : []
      }
    }
  },

  watch: {
    // need to watch if retrieved book orientation is not landscape (component default)
    bookOrientation: {
      immediate: false,
      handler: function (_newValue, _oldValue) {
        this.onResize()
      }
    }
  },

  created: async function () {
    try {
      if (this.content.book) {
        this.book = this.content.book
        console.debug('[BookPlayer]: book.inline=', this.book)
      } else {
        this.book = await contentService.fetchBook({ bookId: this.bookId, bookPath: this.bookPath })
        console.debug('[BookPlayer]: book.fetched=', this.book)
      }
    } catch (error) {
      console.error('[BookPlayer]:', error)
      this.$emit('error:loading')
    } finally {
      console.debug(
        `[BookPlayer]: book orientation=${this.bookOrientation} (${this.contentWidth} x ${this.contentHeight})`
      )
      this.isLoaded = true
    }
  },

  mounted: function () {
    window.addEventListener('resize', this.onResize)
    // mobile only (deprecated but extensively supported)
    window.addEventListener('orientationchange', this.onResize)

    this.onResize()
  },

  beforeDestroy: function () {
    window.removeEventListener('orientationchange', this.onResize)
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    makeAbsolute(urls) {
      return urls.map((url) => (url.startsWith('http') ? url : `${this.bookPath}/${url}`))
    },

    onResize() {
      // determine device orientation
      this.isLandscapeMode = window.innerWidth > window.innerHeight

      // rescale slides
      if (this.areSlidesPages) {
        this.scaleSlides()
      }
    },

    scaleSlides() {
      // const rect = this.$refs.container.getBoundingClientRect()
      const clientHeight = this.isFullscreen
        ? window.screen.height
        : this.$refs.container.clientHeight
      const clientWidth = this.isFullscreen ? window.screen.width : this.$refs.container.clientWidth
      const scale = Math.min(clientHeight / this.contentHeight, clientWidth / this.contentWidth)
      this.$refs.container.style.setProperty('--c-scale', scale || 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-container {
  --c-scale: 1;
  max-height: calc(var(--c-content-viewport-height));
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &.c-fullscreen {
    width: 100%;
    max-height: 100vh;
    max-height: 100dvh;
  }

  .c-thumb {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* image slides */
.c-image-wrapper {
  text-align: center;
  max-height: 100%;
  width: 100%;
  height: 100%;

  .c-image {
    object-fit: contain;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
    height: 100%;
  }
}

/* html slides */
.c-iframe-wrapper {
  position: relative;
  overflow-y: hidden;
  margin: auto;
  width: 100%;
  height: 100%;

  &.xc-landscape {
    width: 1024px;
    height: 768px;
  }

  &.xc-portrait {
    width: 768px;
    height: 1024px;
  }

  .c-iframe {
    position: absolute;
    top: 0;
    display: block;
    border: none;
    -moz-transform: scale(var(--c-scale));
    -ms-transform: scale(var(--c-scale));
    -webkit-transform: scale(var(--c-scale));
    transform: scale(var(--c-scale), var(--c-scale));
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }
}

/* prevent Chrome from scaling twice */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .c-iframe {
    zoom: 1;
  }
}
</style>
