// preloaded routes
import LoginPage from '@/pages/LoginPage'
import HomeView from '@/views/HomeView'
// import PlayerPage from '@/pages/PlayerPage'
import PlayerPage from '@/pages/PlayerPage'
import RouterPage from '@/pages/RouterPage'
import store from '@/store'

// lazy loading
function loadView(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}
function loadPage(page) {
  return () => import(/* webpackChunkName: "[request]" */ `@/pages/${page}.vue`)
}

// redirection
const getOrgIndexRoute = (query) => ({
  ...store.state.menuStore.indexRoute,
  query
})

/*
// work in progress - org and  user custom "Home" routes
const getOrgHomeRoute = (query) => {
  const orgHomeMenuItem = store.state.menuStore.homeMenuItem
  const userHomeMenuItem = store.state.userStore.settings?.homeMenuItem
  const homeMenuItem = userHomeMenuItem || orgHomeMenuItem
  const homeMenuRoute = { name: homeMenuItem }
  return {
    ...homeMenuRoute,
    query
  }
}
*/

// eslint-disable-next-line no-unused-vars
const isRedirectFromLogin = (to) => {
  const hash = to.hash
  const search = window.location.search
  const isRedirect =
    // msal
    hash.startsWith('#state') ||
    hash.startsWith('#code') ||
    hash.startsWith('#error') ||
    // auth0
    (search.includes('state=') && (search.includes('code=') || search.includes('error=')))

  return isRedirect
}

export const appRoutes = [
  {
    path: '/',
    name: 'index',
    redirect: (to) => getOrgIndexRoute(to.query)
  },

  {
    path: '/error',
    name: 'error',
    component: loadPage('ErrorPage'),
    props: (route) => ({
      code: route.query.code,
      message: route.query.message
    }),
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: false
    }
  },
  {
    path: '/jitsi/:room',
    name: 'jitsi',
    component: loadPage('JitsiPage'),
    props: (route) => ({
      subject: route.query.subject,
      room: route.params.room,
      token: route.query.token
    }),
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: (route) => ({
      force: !!route.query?.force,
      redirect: route.query?.redirect,
      options: route.query?.options
    }),
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: false,
      capture: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: loadPage('LogoutPage'),
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: false,
      capture: false
    }
  },
  {
    path: '/meet/:room',
    name: 'meet',
    component: loadPage('JitsiPage'),
    props: true,
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: RouterPage,
    props: (route) => ({
      redirect: route.query.redirect
    }),
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: false,
      capture: false
    }
  },
  {
    path: '/splash',
    name: 'splash',
    component: loadPage('SplashPage'),
    props: (route) => ({
      params: route.query
    }),
    meta: {
      layout: 'ScreenLayout',
      requiresAuthentication: false
    }
  }
]

export const pageRoutes = [
  {
    path: '/join',
    name: 'join',
    component: loadPage('JoinPage'),
    meta: {
      layout: 'PageLayout',
      requiresAuthentication: false
    }
  },
  {
    path: '/channel/:slug',
    name: 'channel',
    component: loadPage('ChannelPage'),
    props: true,
    meta: {
      component: 'ChannelPage',
      keepAlive: true,
      layout: 'PageLayout',
      requiresAuthentication: false,
      sharable: true
    }
  },
  {
    path: '/ichannel/:slug',
    name: 'ichannel',
    component: loadPage('ChannelPage'),
    props: true,
    meta: {
      component: 'ChannelPage',
      keepAlive: true,
      layout: 'PageLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/player/:id',
    name: 'player',
    component: PlayerPage,
    props: true,
    meta: {
      keepAlive: false,
      layout: 'PlayerLayout',
      requiresAuthentication: false,
      sharable: true
    }
  },
  {
    path: '/iplayer/:id',
    name: 'iplayer',
    component: PlayerPage,
    props: true,
    meta: {
      keepAlive: false,
      layout: 'PlayerLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/post/:id',
    name: 'post',
    component: loadPage('PostPage'),
    props: true,
    meta: {
      keepAlive: false,
      layout: 'PageLayout',
      requiresAuthentication: false,
      sharable: true
    }
  },
  {
    path: '/ipost/:id',
    name: 'ipost',
    component: loadPage('PostPage'),
    props: true,
    meta: {
      keepAlive: false,
      layout: 'PageLayout',
      requiresAuthentication: true
    }
  }
]

export const menuRoutes = [
  {
    path: '/about',
    name: 'about',
    component: loadView('AboutView'),
    meta: {
      keepAlive: false,
      layout: 'AppLayout',
      requiresAuthentication: false
    }
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: loadView('FavouritesView'),
    meta: {
      component: 'FavouritesView',
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/channels',
    name: 'channels',
    component: loadView('ChannelsView'),
    meta: {
      component: 'ChannelsView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: loadView('ClientsView'),
    meta: {
      component: 'ClientsView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/collection/:keyword',
    name: 'collection',
    component: loadView('ContentView'),
    props: true,
    meta: {
      component: 'ContentView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: loadView('CompaniesView'),
    meta: {
      component: 'CompaniesView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: loadView('ContactsView'),
    meta: {
      component: 'ContactsView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: loadView('DashboardView'),
    meta: {
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/help',
    name: 'help',
    component: loadView('HelpView'),
    meta: {
      keepAlive: false,
      layout: 'AppLayout',
      requiresAuthentication: false
    }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      component: 'HomeView',
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/library',
    name: 'library',
    component: loadView('ContentView'),
    props: {
      keyword: 'library'
    },
    meta: {
      component: 'ContentView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: loadView('MeetingView'),
    meta: {
      keepAlive: false,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/faculty',
    name: 'persons',
    component: loadView('PersonsView'),
    meta: {
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/faculty',
    name: 'faculty',
    component: loadView('PersonsView'),
    meta: {
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/posts',
    name: 'posts',
    component: loadView('PostsView'),
    meta: {
      component: 'PostsView',
      hasCount: true,
      keepAlive: true,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: loadView('ScheduleView'),
    meta: {
      keepAlive: false,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/tools',
    name: 'tools',
    component: loadView('ToolsView'),
    meta: {
      keepAlive: false,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  },
  {
    path: '/web',
    name: 'web',
    component: loadView('WebView'),
    props: (route) => ({
      href: route.query.href
    }),
    meta: {
      keepAlive: false,
      layout: 'AppLayout',
      requiresAuthentication: true
    }
  }
]

export const errorRoutes = [
  {
    path: '/404',
    name: 'error404',
    component: loadPage('Error404Page'),
    meta: {
      keepAlive: false,
      layout: 'ScreenLayout',
      requiresAuthentication: false
    }
  },
  {
    path: '*',
    name: 'redirect404',
    redirect: { name: 'error404' },
    meta: {
      requiresAuthentication: false
    }
  }
]

// https://stackoverflow.com/questions/48515023/display-different-vuejs-components-for-mobile-browsers
// component: responsiveRoute({ // takes this input and returns the right answer
//   route level code-splitting
//   this generates a separate chunk (login-xs.[hash].js) for this route
//   which is lazy-loaded when the route is visited.
// xs: () => import(/* webpackChunkName: "login-xs" */ '@/views/Login/Login-xs.vue'),
//   sm key is missing, it falls back to xs
// md: () => import(/* webpackChunkName: "login-md" */ '@/views/Login/Login-md.vue')
//   lg, xl keys are missing falls back to md
// })
