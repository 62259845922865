<template>
  <div class="c-content-details g-text-oversized">
    <h2 class="c-heading text-heading-2 text-left mb-4">
      {{ heading }}
    </h2>

    <RichText v-if="hasDescription" :blocks="description" />

    <ContentTabs v-if="hasTabs" :tabs="tabs" />
  </div>
</template>

<script>
import ContentTabs from '@/components/content/ContentTabs'
import RichText from '@/components/base/RichText'

export default {
  name: 'ContentDetails',

  components: {
    ContentTabs,
    RichText
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    body: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  computed: {
    heading() {
      return this.item?.details?.heading || this.item?.title
    },

    description() {
      return this.body.length > 0 ? this.body : this.item?.details?.description || []
    },

    hasDescription() {
      return this.description.length > 0 || this.body.length > 0
    },

    tabs() {
      return this.item?.details?.tabs || []
    },

    hasTabs() {
      return this.tabs.length > 0
    }
  }
}
</script>

<style lang="css" scoped>
.c-heading {
  line-height: 1.2;
}
</style>
