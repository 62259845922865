<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    :color="bannerColour"
    dense
    fixed
  >
    <div class="c-left text-left">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        v-tooltip="$t('tooltip.playerBar.details')"
        class="c-btn c-btn--stateless"
        :data-title="$t('tour.playerPage.drawerOpen.title')"
        :data-intro="$t('tour.playerPage.drawerOpen.intro')"
        :data-toggle="isAsideOpen ? 'nop' : 'begin'"
        fab
        small
        text
        @click="toggleAside"
      >
        <v-icon x-large>
          {{ aside ? 'mdi-backburger' : 'mdi-forwardburger' }}
        </v-icon>
      </v-btn>
    </div>

    <!--v-spacer /-->

    <v-toolbar-title
      v-tooltip="titleTooltip"
      class="c-title px-1 text-h6 text-center"
    >
      {{ title }}
    </v-toolbar-title>

    <!--v-spacer/-->

    <div
      class="c-right text-right"
      :class="{ 'c-full': !isMobile }"
    >
      <template v-if="!isMobile">
        <div></div>
        <div
          class="d-flex"
          :data-title="$t('tour.playerPage.barActions.title')"
          :data-intro="$t('tour.playerPage.barActions.intro')"
          data-position="bottom-right-aligned"
        >
          <v-btn
            v-for="button in buttons"
            :key="button.title"
            v-tooltip="button.tooltip"
            class="c-btn c-btn--stateless ml-2"
            :data-title="button.title"
            :data-intro="button.tooltip"
            fab
            small
            text
            @click="button.action"
          >
            <v-icon :class="button.class()" x-large>
              {{ button.icon[button.choice() ? 0 : 1] }}
            </v-icon>
          </v-btn>
        </div>
      </template>
      <template v-else>
        <v-speed-dial v-model="showActions" direction="bottom" transition="slide-y-transition">
          <template #activator>
            <v-btn
              v-model="showActions"
              class="c-btn"
              :color="bannerColour"
              fab
              small
            >
              <v-icon :class="{ 'accent--text': showActions }" x-large>
                {{ showActions ? 'mdi-close' : 'mdi-dots-vertical' }}
              </v-icon>
            </v-btn>
          </template>
          <v-btn
            v-for="button in buttonsReversed"
            :key="button.title"
            v-ripple="{ class: 'primary--text' }"
            class="c-btn c-btn--stateless c-btn-dial"
            :color="buttonBackgroundColour"
            fab
            @click="button.action"
          >
            <v-icon :class="button.class()" x-large>
              {{ button.icon[button.choice() ? 0 : 1] }}
            </v-icon>
          </v-btn>
        </v-speed-dial>
      </template>
    </div>

    <v-progress-circular
      v-if="isDownloading"
      class="c-progress"
      color="light-green accent-4"
      :rotate="-90"
      :size="100"
      :width="15"
      :value="downloadPercentage"
    >
      {{ downloadPercentage }}
    </v-progress-circular>

    <ErrorToast v-model="showError">
      {{ errorMessage }}
    </ErrorToast>
  </v-app-bar>
</template>

<script>
import ErrorToast from '@/components/base/ErrorToast'
import mobileMixin from '@/mixins/mobileMixin'
import { mapActions } from 'vuex'
// import { download } from '@/utilities/download.js'

const DENSE_BAR_HEIGHT = 48

export default {
  name: 'PlayerBar',

  components: {
    ErrorToast
  },

  mixins: [mobileMixin],

  model: {
    prop: 'aside',
    event: 'toggle:aside'
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    },

    aside: {
      required: true,
      validator: (prop) => typeof prop === 'boolean' || prop === null
    }
  },

  data: function () {
    return {
      isAsideOpen: this.aside === null ? !this.$vuetify.breakpoint.mobile : !!this.aside,
      isDrawMode: false,
      isDownloading: false,
      downloadPercentage: 0,
      showError: false,
      errorMessage: '',
      isFavourite: false,
      isFull: false,
      showActions: false,
      showComments: false
    }
  },

  computed: {
    /* */

    isAuthenticated() {
      return this.$auth.isAuthenticated()
    },

    /* item */

    isShareable() {
      return !this.item?.doNotShare
    },

    itemId() {
      return this.item?.id || ''
    },

    title() {
      return this.item?.title || ''
    },

    titleTooltip() {
      return {
        content: this.title,
        theme: 'overflow'
      }
    },

    /* context */

    hasMaxmin() {
      return document.fullscreenEnabled || document.webkitFullscreenEnabled
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    favourite() {
      return this.$store.state.userStore.favourites.indexOf(this.itemId) > -1
    },

    /* colours */

    bannerColour() {
      // return this.isDark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'
      return this.isDark ? 'black' : 'primary'
    },

    buttonBackgroundColour() {
      return this.isDark ? 'rgba(100, 100, 100, 0.8)' : 'rgba(220, 220, 220, 0.8)'
    },

    favColour() {
      return this.isFavourite ? 'red--text' : ''
    },

    /* buttons */

    buttons() {
      return this.buttonData.filter((button) => button.enabled)
    },

    buttonsReversed() {
      return this.buttons.slice().reverse()
    },

    buttonData() {
      return [
        {
          title: this.$t('playerBar.labels.comments'),
          tooltip: this.$t('tooltip.playerBar.comments'),
          enabled: this.$feature('comments') && this.isAuthenticated,
          action: this.toggleComments,
          choice: () => true,
          class: () => '',
          icon: ['mdi-comment-text', 'mdi-comment-text']
        },
        {
          title: this.$t('playerBar.labels.favourite'),
          tooltip: this.$t('tooltip.playerBar.favourite'),
          enabled: this.isAuthenticated,
          action: this.toggleFavourite,
          choice: () => this.isFavourite,
          class: () => 'red--text',
          icon: ['mdi-heart', 'mdi-heart-outline']
        },
        {
          title: this.$t('playerBar.labels.maximize'),
          tooltip: this.$t('tooltip.playerBar.maximize'),
          enabled: this.hasMaxmin ? true : false,
          action: this.onMaxMin,
          choice: () => this.isFull,
          class: () => '',
          icon: ['mdi-fullscreen-exit', 'mdi-fullscreen']
        },
        {
          title: this.$t('playerBar.labels.mode'),
          tooltip: this.$t('tooltip.playerBar.mode'),
          enabled: true,
          action: this.toggleDarkMode,
          choice: () => this.isDark,
          class: () => '',
          icon: ['mdi-yin-yang', 'mdi-yin-yang']
        },
        {
          title: this.$t('playerBar.labels.share'),
          tooltip: this.isShareable
            ? this.$t('tooltip.playerBar.share')
            : this.$t('tooltip.playerBar.noshare'),
          enabled: this.$actions.share && this.isAuthenticated,
          action: this.isShareable ? this.shareItem : () => {},
          choice: () => this.isShareable,
          class: () => '',
          icon: ['mdi-share-variant', 'mdi-security']
        },
        {
          title: this.$t('playerBar.labels.download'),
          tooltip: this.$t('tooltip.playerBar.download'),
          enabled: this.item?.downloadURL,
          action: this.downloadItem,
          choice: () => true,
          class: () => '',
          icon: ['mdi-download', 'mdi-download']
        },
        {
          title: this.$t('playerBar.labels.draw'),
          tooltip: this.$t('tooltip.playerBar.draw'),
          enabled: this.$actions.draw,
          action: this.toggleDrawMode,
          choice: () => this.isDrawMode,
          class: () => (this.isDrawMode ? 'yellow--text' : ''),
          icon: ['mdi-pencil-off', 'mdi-pencil']
        },
        {
          title: this.$t('playerBar.labels.close'),
          tooltip: this.$t('tooltip.playerBar.close'),
          enabled: true,
          action: this.closePlayer,
          choice: () => true,
          class: () => '',
          icon: ['mdi-exit-to-app', 'mdi-exit-to-app']
        }
      ]
    }
  },

  watch: {
    item: {
      immediate: false,
      handler: function (_newItem, _oldItem) {
        this.isFavourite = this.favourite
      }
    },

    isMobile: {
      immediate: true,
      handler: function (_newMobile, _oldMobile) {
        // because we are using this handler immediately we need to wait for data changes using nextTick
        this.$nextTick(() => {
          const root = document.documentElement
          this.isMobile
            ? root.style.setProperty('--c-player-bar-height', `${DENSE_BAR_HEIGHT}px`)
            : root.style.setProperty('--c-player-bar-height', `${DENSE_BAR_HEIGHT}px`)
        })
      }
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  mounted: function () {
    this.$emit('height', DENSE_BAR_HEIGHT)
    document.addEventListener('fullscreenchange', this.onFullscreenChange)
    document.addEventListener('webkitfullscreenchange', this.onFullscreenChange)
  },

  beforeDestroy: function () {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange)
    document.removeEventListener('webkitfullscreenchange', this.onFullscreenChange)
  },

  methods: {
    ...mapActions('themeStore', ['updateMode']),
    ...mapActions('userStore', ['addToFavourites', 'removeFromFavourites']),

    async downloadItem() {
      try {
        const url = this.item.downloadURL
        this.isDownloading = true

        // const item = await fetch(url)
        // const itemBlob = await item.blob()
        const itemBlob = await this.downloadBlob(url)

        const anchor = document.createElement('a')
        anchor.href = URL.createObjectURL(itemBlob)
        anchor.download = url.split('/').at(-1)

        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
      } catch {
        this.errorMessage = this.$t('playerBar.message.downloadError')
        this.showError = true
      } finally {
        this.downloadPercentage = 0
        this.isDownloading = false
      }
    },

    async downloadBlob(url) {
      const response = await fetch(url)
      const contentLength = response.headers.get('Content-Length')
      const total = parseInt(contentLength, 10)
      const values = []
      let loaded = 0

      const reader = response.body.getReader()
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        values.push(value)
        loaded += value.byteLength
        const percentage = Math.ceil((loaded / total) * 100)
        this.downloadPercentage = Math.ceil(percentage / 5) * 5 // nearest 5%
      }
      this.downloadPercentage = 100
      const blob = new Blob(values)
      return blob
    },

    // manage sharing
    shareItem() {
      this.$emit('share', this.item)
    },

    // manage content aside
    toggleAside() {
      this.isAsideOpen = !this.isAsideOpen
      this.$emit('toggle:aside', this.isAsideOpen)
    },

    // manage content aside
    toggleComments() {
      this.showComments = !this.showComments
      this.$emit('toggle:comments', this.showComments)
    },

    //manage dark mode
    toggleDarkMode() {
      this.updateMode({ isDark: !this.isDark, vm: this })
    },

    //manage draw mode
    toggleDrawMode() {
      this.isDrawMode = !this.isDrawMode
      this.$emit('activate:canvas', this.isDrawMode)
    },

    // manage favourites
    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      if (this.isFavourite) {
        this.addToFavourites({ id: this.itemId })
        this.$emit('add:favourite')
      } else {
        this.removeFromFavourites({ id: this.itemId })
        this.$emit('remove:favourite')
      }
    },

    //manage window
    closePlayer() {
      if (this.isAsideOpen) this.toggleAside()
      if (this.isDrawMode) this.toggleDrawMode()
      this.$emit('close')
    },

    isFullscreen() {
      /*
        document.fullscreenElement will point to the element that
        is in fullscreen mode (if there is one)

        - IE uses msFullscreenElement
        - older Mozilla uses mozFullScreenElement
        - Safari requires a webkit prefix
      */
      return !!(document.fullscreenElement || document.webkitFullscreenElement)
    },

    isFullscreenVideo() {
      const videoElement = document.fullscreenElement || document.webkitFullscreenElement
      return videoElement && videoElement.nodeName === 'VIDEO'
    },

    onMaxMin() {
      this.isFull ? this.exitFullscreen() : this.requestFullscreen()
    },

    onFullscreenChange() {
      this.$emit('fullscreen', this.isFullscreen())
    },

    requestFullscreen() {
      if (!this.isFullscreen()) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen()
        }
      }
    },

    exitFullscreen() {
      if (this.isFullscreen()) {
        if (document.documentElement.exitFullscreen) {
          document.documentElement.exitFullscreen()
        } else if (document.documentElement.webkitExitFullscreen) {
          document.documentElement.webkitExitFullscreen()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-left {
  flex: 1 1 0;
}
.c-right {
  flex: 1 1 0;
}
.c-right.c-full {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.c-title {
  flex: auto; /* grows and shrinks */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.c-favourite {
  color: red;
}

.c-btn-dial {
  background-color: rgba(100, 100, 100, 0.8);
}

.c-progress {
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translateX(50%) translateY(50%);

  & :deep(.v-progress-circular__info) {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>
