import posthog from 'posthog-js'
import { GTM_ID, POSTHOG_API_URL, POSTHOG_KEY, TRACKING_URL } from '@/config/appConfig.js'
import { isLocalhost } from '@/utilities/urlUtils.js'
import { isPWA } from '@/pwa.js'

/* Custom Events
 * - app_start
 * - app_exit
 * - ad_click
 * - ad_view
 * - channel_view
 * - channel_close
 * - content_view
 * - content_close
 * - post_view
 * - post_close
 * - user_login
 * - user_logout
 * - pageview
 * - pageleave
 */

class AnalyticsService {
  constructor() {
    this.type = ''
    this.trace = false
    this.live = !isLocalhost()
    this.posthogKey = POSTHOG_KEY
    this.gtmId = GTM_ID
    this.isPWA = isPWA()

    // Posthog
    if (this.posthogKey) {
      // see https://posthog.com/docs/libraries/js#config
      posthog.init(this.posthogKey, {
        api_host: POSTHOG_API_URL,
        // https://posthog.com/docs/product-analytics/autocapture#autocaptured-properties
        autocapture: true,
        before_send: (e) => this._beforeSend(e),
        capture_pageview: false,
        capture_pageleave: false,
        disable_session_recording: true,
        enable_recording_console_log: false,
        enable_heatmaps: true,
        loaded: (p) => this._onStartup(p),
        opt_out_capturing_by_default: false,
        opt_out_persistence_by_default: false,
        secure_cookie: true,
        session_idle_timeout_seconds: 1800 // 30 minutes
      })
    }
  }

  _beforeSend(event) {
    if (event) {
      // remove login hash from url
      if (event.properties.$current_url) {
        event.properties.$current_url = event.properties.$current_url.split('#')[0]
      }

      if (this.trace) {
        if (event.event === '$pageview' || event.event === '$pageleave') {
          console.debug(`[posthog]: ${event.event} - ${event.properties.$current_url}`)
        }
      }

      // return event to log for real
      return this.live ? event : null
    } else {
      return null
    }
  }

  _onFeatureFlags() {
    console.debug('[analyticsService]: Feature flags are active!')
  }

  _onStartup(posthog) {
    if (this.live) {
      posthog.capture('app_start', { app_type: this.isPWA ? 'pwa' : 'web' })
      if (process.env.NODE_ENV !== 'development') posthog.startSessionRecording()
    }
  }

  activate({ w, d, keys }) {
    if (this.live) {
      // Google
      if (keys.gtm) {
        console.debug('[analyticsService]: Activating Google Tag Manager.')
        const s = 'script'
        const l = 'dataLayer'
        const i = keys.gtm

        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      }

      // Posthog
      if (keys.posthog) {
        console.debug('[analyticsService]: Activating Posthog.')
      }
    }
  }

  identify({ name, email, subject }) {
    if (this.trace) {
      console.debug('[analyticsService]: identify=', name, email, subject)
    }

    if (this.live) {
      // Google
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        user_name: name,
        user_email: email,
        user_id: subject
      })

      // Posthog
      posthog.identify(subject, { name, email, subject })
    }

    posthog.onFeatureFlags(() => {
      // check for user specific feature flags
      if (posthog.isFeatureEnabled('cookies')) {
        console.debug('[analyticsService]: "cookie" feature flag is on!')
      }
    })
  }

  capture(event, properties) {
    if (this.trace) {
      console.debug('[analyticsService]:', event, properties)
    }

    if (this.live) {
      // Google
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: event,
        ...properties
      })

      // Posthog
      posthog.capture(event, properties)
    }
  }

  enableCapture() {
    if (this.live) {
      // posthog.opt_in_capturing()
    }
  }

  disableCapture() {
    if (this.live) {
      // posthog.opt_out_capturing()
    }
  }

  getCookiePermission() {
    const permission = posthog.has_opted_in_capturing()
      ? 'enabled'
      : posthog.has_opted_out_capturing()
      ? 'disabled'
      : 'prompt'

    return permission
  }

  reset() {
    if (this.live) {
      posthog.reset(true)
    }
  }

  sendBeacon(event, properties) {
    if (this.live) {
      // TODO: allow other events to be posted
      if (event !== 'adView' && event !== 'adClick') {
        return
      }

      const url = TRACKING_URL

      // construct form data
      const formData = new FormData()
      formData.append('event', event)
      for (const [key, value] of Object.entries(properties)) {
        formData.append(key, value)
      }

      // trace formData
      if (this.trace) {
        for (const nvp of formData.entries()) {
          console.debug(`${nvp[0]}: ${nvp[1]}`)
        }
      }

      // fire POST event
      if (navigator.sendBeacon) {
        const result = navigator.sendBeacon(url, formData)
        if (!result) {
          console.error('[analyticsService]: Call to sendBeacon() failed.')
        }
      } else {
        fetch(url, {
          method: 'post',
          body: formData,
          mode: 'no-cors',
          keepalive: true
        })
      }
    }
  }
}

export default AnalyticsService
export const analyticsService = new AnalyticsService()
