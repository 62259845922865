import store from '@/store'
import headers from './headers.json'

export const fetchContent = async ({ filter = null, collectionKey = null }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  // create the query string
  const params = {
    filter: filter,
    collection: collectionKey
  }
  const esc = encodeURIComponent
  const query = Object.keys(params)
    .filter((k) => !!params[k])
    .map((k) => `${k}=${esc(params[k])}`)
    .join('&')

  const queryString = query.length > 0 ? `?${query}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/items${queryString}`)

    if (response.ok) {
      return response.json() // intentionally return a promise
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[contentService]:', error)
    throw error
  }
}

export const getContentHeaders = () => {
  return JSON.parse(JSON.stringify(headers))
}

export const fetchContentItem = async ({ id, slug }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = id
      ? await fetch(`/api/tenant/${tenantKey}/item/${id}`)
      : await fetch(`/api/tenant/${tenantKey}/item/slug/${slug}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[contentService]:', error)
    throw error
  }
}
