<template>
  <!-- hack: https://github.com/vuetifyjs/vuetify/issues/11944 -->
  <v-app-bar
    v-show="show"
    class="c-bottom-appbar"
    app
    bottom
    height="76"
    hide-on-scroll
  >
    <v-bottom-navigation
      v-model="selectedItem"
      class="c-bottom-navigation g-hide-scrollbar pt-1"
      :background-color="isDark ? 'background' : '#f0f0f0'"
      color="accent"
      grow
      height="76"
    >
      <v-btn
        v-for="menuItem in menuItems"
        :key="menuItem.key"
        class="c-menu-item px-1"
        :data-title="menuTitles[menuItem.key]"
        :data-intro="menuIntros[menuItem.key]"
        data-click
        active-class="c-selected-item"
        :to="to(menuItem)"
        :value="menuItem.key"
        @click.stop="selectItem(menuItem)"
      >
        <v-icon x-large>
          {{ menuItem.icon }}
        </v-icon>
        <span class="c-menu-item__title">{{ menuTitles[menuItem.key] }}</span>
      </v-btn>
    </v-bottom-navigation>
  </v-app-bar>
</template>

<script>
/*
 * Note:
 * The "app" attribute adds padding to v-main, however,
 * this causes another scrollbar to appear (at <html>)
 */
import { mapGetters } from 'vuex'

export default {
  name: 'AppBottomBar',

  components: {},

  props: {
    show: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      selectedItem: null
    }
  },

  computed: {
    ...mapGetters('menuStore', ['getMenuTitles', 'getMenuIntros']),

    menuItems() {
      return this.$store.state.menuStore.menuItems
    },

    menuTitles() {
      return this.getMenuTitles(this.locale)
    },

    menuIntros() {
      return this.getMenuIntros(this.locale)
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  created: function () {
    this.selectedItem =
      this.menuItems
        .filter((menuItem) => menuItem.key === this.$route.name)
        .map((menuItem) => menuItem.key)?.[0] || 'home'
  },

  methods: {
    selectItem(menuItem) {
      this.selectedItem = menuItem.key
    },

    to(menuItem) {
      return menuItem.to ? menuItem.to : { name: menuItem.route }
    }
  }
}
</script>

<style lang="scss" scoped>
/* menu bar */

.c-bottom-appbar :deep(.v-toolbar__content) {
  padding: 0px;
}

.c-bottom-navigation {
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .c-menu-item:first-child {
    margin-left: auto;
  }
  .c-menu-item:last-child {
    margin-right: auto;
  }
}

/* menu items */
.c-menu-item {
  display: block;
  justify-content: flex-start;
  white-space: wrap;

  &.c-selected-item {
    color: var(--v-accent-base) !important;
  }

  :deep(.v-btn__content) {
    flex-direction: column !important; /* default is column-reverse */
  }

  .c-menu-item__title {
    text-align: center;
  }
}

/* hack to add invert-scroll effect to bottom navigatioin bar */
.c-bottom-appbar >>> .v-toolbar__content {
  padding: 0;
  margin: 0;
}

.v-app-bar >>> .v-app-bar--fixed {
  top: initial !important;
  bottom: 0 !important;
}

.v-app-bar.v-app-bar--fixed {
  top: initial !important;
  bottom: 0 !important;
}

.v-app-bar--fixed {
  top: initial !important;
  bottom: 0 !important;
}

.v-app-bar {
  top: initial !important;
  bottom: 0 !important;
}
</style>
