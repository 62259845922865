import { render, staticRenderFns } from "./UnityApp.vue?vue&type=template&id=b8045d18&scoped=true"
import script from "./UnityApp.vue?vue&type=script&lang=js"
export * from "./UnityApp.vue?vue&type=script&lang=js"
import style0 from "./UnityApp.vue?vue&type=style&index=0&id=b8045d18&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8045d18",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8045d18')) {
      api.createRecord('b8045d18', component.options)
    } else {
      api.reload('b8045d18', component.options)
    }
    module.hot.accept("./UnityApp.vue?vue&type=template&id=b8045d18&scoped=true", function () {
      api.rerender('b8045d18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/players/ModelPlayer/components/Unity/UnityApp.vue"
export default component.exports