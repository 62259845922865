import posthog from 'posthog-js'

// external feature flags
const enableAll = process.env.VUE_APP_ENABLE_ALL_FEATURES === 'true'

// following features are off by default
const pwaFeature = process.env.VUE_APP_FEATURE_PWA === 'true'
const pwaNotifications = process.env.VUE_APP_FEATURE_PWA_NOTIFICATIONS === 'true'

// following features are on by default
const commentsFeature = !(process.env.VUE_APP_FEATURE_COMMENTS === 'false')
const sharingFeature = !(process.env.VUE_APP_FEATURE_SHARING === 'false')

export const features = {
  // account
  accountProfile: false,
  accountSettings: false,
  tours: false,
  cookies: false,
  // cards
  contactInfo: false,
  contentSizes: false,
  // drawing tools
  advancedDrawTools: false,
  drawMode: false,
  // app bar
  notices: false,
  onlineStatus: true,
  // pwa
  pwa: pwaFeature,
  pwaInstall: pwaFeature && true,
  pwaNotifications: pwaFeature && pwaNotifications,
  pwaRandomNotifications: false,
  pwaRegister: pwaFeature && true,
  pwaDeregister: false,
  pwaArchiving: false,
  pwaSkipWaiting: true,
  // social
  comments: commentsFeature,
  sharing: sharingFeature
}

export const getFeature = (name) => {
  if (posthog.isFeatureEnabled(name)) {
    console.debug(`[featureConfig]: Feature "${name}" is enabled via Posthog!`)
    return true
  } else {
    const enabled = enableAll || features[name]
    console.debug(`[featureConfig]: Feature "${name}" is ${enabled}!`)
    return enabled
  }
}

export const cardActions = {
  archive: getFeature('pwaArchiving'),
  draw: getFeature('drawMode'),
  contact: getFeature('contactInfo'),
  share: getFeature('sharing'),
  size: getFeature('contentSizes')
}
