<template>
  <!-- note: v-app-bar applies -12px margin to first/last child (buttons only) -->
  <v-app-bar
    ref="bar"
    class="c-app-bar"
    :style="{ marginLeft: $vuetify.application.left + 'px' }"
    app
    :clipped-left="clipped"
    :clipped-right="clipped"
    color="primary"
    dense
    elevation="0"
    :hide-on-scroll="false"
    :inverted-scroll="false"
    scroll-target="#main"
  >
    <!-- left nav controller -->
    <v-btn
      v-tooltip="$t('tooltip.appBar.menu')"
      :data-title="$t('tour.appBar.menuDisplayButton.title')"
      :data-intro="$t('tour.appBar.menuDisplayButton.intro')"
      icon
      @click="$emit('toggle')"
    >
      <v-icon color="icon" x-large>
        {{ menu ? 'mdi-menu-open' : 'mdi-menu' }}
      </v-icon>
    </v-btn>

    <!-- title -->
    <v-toolbar-title class="c-title"> {{ pageTitle }} {{ pageSubtitle }} </v-toolbar-title>

    <v-spacer />

    <!-- control buttons -->
    <div class="c-controls d-flex justify-space-between align-center">
      <AppInstall v-if="showInstallButton" />
      <AppOnlineStatus v-if="showOnlineStatus" :offline-only="true" class="ml-2" />
      <AppNotices v-if="hasNotices" class="ml-2" />
      <AccountMenu :activator-class="'ml-2'" />
    </div>

    <!-- dropdown dialog -->
    <AppNotificationPermission v-if="hasNotifications" />
  </v-app-bar>
</template>

<script>
// components
import AccountMenu from '@/components/account/AccountMenu'
import AppInstall from '@/components/app/AppInstall'
import AppOnlineStatus from '@/components/app/AppOnlineStatus'
import AppNotices from '@/components/app/AppNotices'
import AppNotificationPermission from '@/components/app/AppNotificationPermission'
import pushMixin from '@/mixins/pushMixin.js'

export default {
  name: 'AppTopBar',

  components: {
    AccountMenu,
    AppInstall,
    AppOnlineStatus,
    AppNotices,
    AppNotificationPermission
  },

  mixins: [pushMixin],

  props: {
    left: {
      type: Number,
      required: false,
      default: 0
    },

    menu: {
      type: Boolean,
      required: false,
      default: true
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      clipped: false // false ensures app bar is not covered by the nav bar
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    showInstallButton() {
      return this.$feature('pwaInstall')
    },

    hasNotices() {
      return this.$feature('notices')
    },

    hasNotifications() {
      // on iOS, push notifications are only supported for "installed" apps (i.e. added to homescreen)
      return this.$feature('pwaNotifications') && this.pushMixin_isPushEnabled
    },

    showOnlineStatus() {
      return this.$feature('onlineStatus')
    },

    hasSubtitle() {
      return !!this.$route.meta?.hasCount
    },

    pageTitle() {
      return this.title
    },

    pageSubtitle() {
      return this.hasSubtitle && this.subtitle ? `(${this.subtitle})` : ''
    }
  }
}
</script>

<style lang="css" scoped>
.c-app-bar {
  position: sticky;
}

.c-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.c-menu-button:focus::before {
  opacity: 0 !important;
}

.c-controls {
  margin-right: -4px;
}
</style>
