<template>
  <div ref="wrapper" class="c-tablet-viewer c-container d-flex align-center justify-center">
    <iframe
      :key="src"
      ref="iframe"
      class="c-iframe"
      allowfullscreen
      :src="src"
      :title="title"
      :width="`${contentWidth}px`"
      :height="`${contentHeight}px`"
      sandbox="allow-modals allow-same-origin allow-scripts allow-popups"
    />
  </div>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin'
import playerMixin from '@/mixins/playerMixin'

export default {
  name: 'TabletViewer',

  components: {},

  mixins: [mobileMixin, playerMixin],

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      contentWidth: 1,
      contentHeight: 1,
      pages: 0,
      src: this.content.contentURL,
      title: this.content.title || 'Untitled'
    }
  },

  computed: {
    contentOrientation() {
      return this.content.params?.find((param) => param.name === 'orientation')?.value
    },

    isFullscreen() {
      return this.fullscreen
    }
  },

  created: function () {
    if (this.contentOrientation === 'portrait') {
      this.contentWidth = 768
      this.contentHeight = 1024
    } else {
      this.contentWidth = 1024
      this.contentHeight = 768
    }
  },

  mounted: function () {
    window.addEventListener('resize', this.onResize)
    this.$refs.iframe.addEventListener('load', this.incrementPage)
    this.onResize()
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
    this.$refs.iframe.removeEventListener('load', this.incrementPage)
  },

  methods: {
    incrementPage() {
      this.pages++
    },

    onResize() {
      const clientHeight = this.isFullscreen
        ? window.screen.height
        : this.$refs.wrapper.clientHeight
      const clientWidth = this.isFullscreen ? window.screen.width : this.$refs.wrapper.clientWidth
      console.debug(`[TabletViewer]: (${this.contentWidth},${this.contentHeight})`)
      console.debug(
        `[TabletViewer]: (${this.$refs.wrapper.clientWidth},${this.$refs.wrapper.clientHeight})`
      )
      const scale = Math.min(clientHeight / this.contentHeight, clientWidth / this.contentWidth)
      this.$refs.wrapper.style.setProperty('--c-scale', scale || 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-tablet-viewer {
  min-height: 100%;
  width: 100%;
}
.c-container {
  --c-scale: 1;
  position: relative;
  overflow-y: hidden;
  margin: auto;

  .c-iframe {
    position: absolute;
    top: 0;
    display: block;
    border: none;
    background-color: #0c0f0e;
    -moz-transform: scale(var(--c-scale));
    -ms-transform: scale(var(--c-scale));
    -webkit-transform: scale(var(--c-scale));
    transform: scale(var(--c-scale), var(--c-scale));
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }
}

/* prevent Chrome from scaling twice */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .c-iframe {
    zoom: 1;
  }
}
</style>
