export default {
  data: function () {
    const portraitQuery = window.matchMedia('(orientation: portrait)')
    // prettier-ignore
    const screenOrientation = window.screen.orientation
      ? window.screen.orientation.type
      : portraitQuery.matches
        ? 'portrait'
        : 'landscape'

    return {
      mobileMixin_portraitQuery: portraitQuery,
      mobileMixin_mediaOrientation: portraitQuery.matches ? 'portrait' : 'landscape',
      mobileMixin_screenOrientation: screenOrientation,
      mobileMixin_height: 0,
      mobileMixin_width: 0
    }
  },

  computed: {
    /* reactive */

    mobileMixin_isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    mobileMixin_isViewportSmall() {
      // < 960px
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },

    mobileMixin_isViewportMedium() {
      // 960px - 1264px
      return this.$vuetify.breakpoint.mdAndUp
    },

    mobileMixin_isViewportLarge() {
      // > 1264px
      return this.$vuetify.breakpoint.lgAndUp
    },

    mobileMixin_isLandscape() {
      return this.mobileMixin_width >= this.mobileMixin_height
    },

    mobileMixin_isPortrait() {
      return this.mobileMixin_width < this.mobileMixin_height
    },

    mobileMixin_isReallyLandscape() {
      return this.mobileMixin_isReallyMobile
        ? this.mobileMixin_deviceOrientation === 'landscape'
        : this.mobileMixin_isLandscape
    },

    mobileMixin_isReallyPortrait() {
      return this.mobileMixin_isReallyMobile
        ? this.mobileMixin_deviceOrientation === 'portrait'
        : this.mobileMixin_isPortrait
    },

    mobileMixin_deviceOrientation() {
      return window.screen.orientation
        ? this.mobileMixin_screenOrientation.startsWith('portrait')
          ? 'portrait'
          : 'landscape'
        : this.mobileMixin_mediaOrientation
    },

    mobileMixin_viewportOrientation() {
      return this.mobileMixin_isLandscape ? 'landscape' : 'portrait'
    },

    mobileMixin_orientation() {
      return this.mobileMixin_isReallyMobile
        ? this.mobileMixin_deviceOrientation
        : this.mobileMixin_viewportOrientation
    },

    mobileMixin_optimalAspectRatio() {
      const ratios = {
        xs: 4 / 1,
        sm: 6 / 1,
        md: 8 / 1,
        lg: 8 / 1,
        xl: 10 / 1
      }
      const breakpoint = this.$vuetify.breakpoint.name

      return ratios[breakpoint] || 8 / 1
    },

    /* static */

    mobileMixin_isMobileOS() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    },

    mobileMixin_isAndroid() {
      return /Android/i.test(window.navigator.userAgent)
    },

    mobileMixin_isiOS() {
      return (
        /iPhone|iPad|iPod/i.test(window.navigator.userAgent) ||
        (window.navigator.userAgent.includes('Macintosh') && window.navigator.maxTouchPoints >= 1)
      )
    },

    mobileMixin_isReallyMobile() {
      return this.mobileMixin_isMobileOS || this.mobileMixin_isiOS
    },

    mobileMixin_isMac() {
      return (
        !this.isiOS &&
        window.navigator.userAgent.includes('Macintosh') &&
        window.navigator.maxTouchPoints < 1
      )
    },

    mobileMixin_isiPad() {
      return (
        /iPad/i.test(window.navigator.userAgent) ||
        (window.navigator.userAgent.includes('Macintosh') && window.navigator.maxTouchPoints >= 1)
      )
    },

    mobileMixin_isiPhone() {
      return /iPhone/i.test(window.navigator.userAgent)
    },

    mobileMixin_iOSVersion() {
      const ua = window.navigator.userAgent
      let versionArray = null
      if (/(iPhone|iPad)/i.test(ua)) {
        // iPhone and iPad (iOS 12 and below)
        versionArray = ua.match(/OS (\d+)_(\d+)_?(\d+)?/)
      } else if (
        window.navigator.userAgent.includes('Macintosh') &&
        window.navigator.maxTouchPoints >= 1
      ) {
        // iPad (iOS 13 and above)
        versionArray = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/)
      }

      const major = versionArray && versionArray[1] ? versionArray[1] : '0'
      const minor = versionArray && versionArray[2] ? versionArray[2] : '0'
      // const patch = versionArray && versionArray[3] ? versionArray[3] : '0'
      const version = Number(`${major}.${minor}`) || 0
      return version
    },

    mobileMixin_browserName() {
      const test = (regexp) => regexp.test(window.navigator.userAgent)
      switch (true) {
        case test(/edg/i):
          return 'Edge'
        case test(/trident/i):
          return 'IE'
        case test(/firefox|fxios/i):
          return 'Firefox'
        case test(/opr\//i):
          return 'Opera'
        case test(/ucbrowser/i):
          return 'UC Browser'
        case test(/samsungbrowser/i):
          return 'Samsung Browser'
        case test(/chrome|chromium|crios/i):
          return 'Chrome'
        case test(/safari/i):
          return 'Safari'
        default:
          return 'Other'
      }
    },

    mobileMixin_isSafari() {
      return /safari/i.test(window.navigator.userAgent)
    },

    mobileMixin_isChromeOrEdge() {
      return /chrome|chromium|crios|edg/i.test(window.navigator.userAgent)
    }
  },

  mounted: function () {
    // set current values
    this.mobileMixin_onMediaChange()
    this.mobileMixin_onResize()

    // register change events
    this.mobileMixin_portraitQuery.addEventListener('change', this.mobileMixin_onMediaChange)
    window.addEventListener('resize', this.mobileMixin_onResize)
    window.screen.orientation &&
      window.screen.orientation.addEventListener('change', this.mobileMixin_onOrientationChange)
  },

  beforeDestroy: function () {
    this.mobileMixin_portraitQuery.removeEventListener('change', this.mobileMixin_onMediaChange)
    window.removeEventListener('resize', this.mobileMixin_onResize)
    window.screen.orientation &&
      window.screen.orientation.removeEventListener('change', this.mobileMixin_onOrientationChange)
  },

  methods: {
    mobileMixin_onMediaChange() {
      this.mobileMixin_portraitQuery.matches
        ? (this.mobileMixin_mediaOrientation = 'portrait')
        : (this.mobileMixin_mediaOrientation = 'landscape')
      // console.warn(`MediaOrientation change: ${this.mobileMixin_mediaOrientation}.`)
    },

    mobileMixin_onOrientationChange(event) {
      const type = event.target.type // document's current orientation type
      const angle = event.target.angle // document's current orientation angle
      this.mobileMixin_screenOrientation = type
      console.warn(`ScreenOrientation change: ${type}, ${angle} degrees.`)

      this.mobileMixin_onResize()
      this.mobileMixin_onMediaChange()
    },

    mobileMixin_onResize() {
      // this.mobileMixin_width = window.innerWidth // includes scrollbars
      // this.mobileMixin_height = window.innerHeight // includes scrollbars
      this.mobileMixin_width = document.documentElement.clientWidth
      this.mobileMixin_height = document.documentElement.clientHeight

      // console.warn(`Resize change: ${this.mobileMixin_width} x ${this.mobileMixin_height}.`)
    }
  }
}
