<template>
  <div
    ref="layout"
    class="c-layout-container c-column"
    :class="{
      'c-fullheight g-skinny-scrollbars': !scroll && !fullscreen,
      'c-fullscreen': fullscreen
    }"
  >
    <!-- header -->
    <header
      v-if="showHeader"
      ref="header"
      class="c-layout-header c-column"
      :class="{ 'c-fullscreen': fullscreen }"
    >
      <slot
        name="header"
        :on="{
          close: onCloseHeader,
          height: setHeaderHeight
        }"
      />
    </header>

    <!-- columns -->
    <main
      ref="main"
      v-scroll.self="onScroll"
      class="c-layout-main d-flex justify-center align-start"
    >
      <!-- left side -->
      <div
        v-if="showLeftPanel"
        class="c-left-side c-layout-side c-column pa-2"
        :class="{
          'c-scrollable g-skinny-scrollbars': isScrollable,
          'c-fullscreen': fullscreen
        }"
      >
        <slot name="left"></slot>
      </div>

      <!-- middle -->
      <div
        class="c-layout-middle c-column"
        :class="{
          'c-solo': !showLeftPanel && !showRightPanel,
          'c-scrollable g-skinny-scrollbars': isScrollable,
          'c-fullscreen': fullscreen
        }"
      >
        <slot name="middle"></slot>
      </div>

      <!-- right side -->
      <div
        v-if="showRightPanel"
        class="c-right-side c-layout-side c-column pa-2"
        :class="{
          'c-scrollable g-skinny-scrollbars': isScrollable,
          'c-fullscreen': fullscreen
        }"
      >
        <slot name="right"></slot>
      </div>
    </main>
  </div>
</template>

<script>
/* Two ways to disable header/sides:
 *   1)by setting header / left / right to false (effectively, display: none)
 *   2)by calling the "close" method passed via the slot props (adds 'c-close' class)
 *
 * Scrolling:
 *   1)Setting scroll=true keeps the header in view.
 *   2)Default is header scrolls (IF parent sets height and overflow).
 */
import mobileMixin from '@/mixins/mobileMixin'

export default {
  name: 'ThreeColumnLayout',

  components: {},

  mixins: [mobileMixin],

  props: {
    header: {
      type: Boolean,
      required: false,
      default: false
    },

    left: {
      type: Boolean,
      required: false,
      default: false
    },

    right: {
      type: Boolean,
      required: false,
      default: false
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    scroll: {
      type: Boolean,
      required: false,
      default: true
    },

    top: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      headerObserver: null,
      mainObserver: null,
      headerHeight: 0,
      mainHeight: 0
    }
  },

  computed: {
    headerProps() {
      return {
        fullscreen: this.fullscreen,
        header: this.header
      }
    },

    isLandscape() {
      return this.mobileMixin_isLandscape
    },

    isScrollable() {
      return this.scroll && !this.fullscreen
    },

    isViewportLarge() {
      return this.mobileMixin_isViewportLarge
    },

    isViewportMedium() {
      return this.mobileMixin_isViewportMedium
    },

    showHeader() {
      return this.header && !this.isLandscape
    },

    showLeftPanel() {
      // css also controls left panel display
      return this.left && this.isLandscape
    },

    showRightPanel() {
      // css also controls right panel display
      return this.right && this.isLandscape
    }
  },

  watch: {
    showHeader: {
      immediate: false, // let mounted() do its thing
      handler: function (newHeader, _oldHeader) {
        if (!newHeader) this.setHeaderHeight(0)
        this.$nextTick(() => {
          // observer only valid if header exists
          newHeader
            ? this.headerObserver?.observe(this.$refs.header)
            : this.headerObserver?.disconnect()
        })
      }
    }
  },

  created: function () {
    this.headerObserver = new ResizeObserver(this.onHeaderResize)
    this.mainObserver = new ResizeObserver(this.onMainResize)
  },

  mounted: function () {
    if (this.$refs.header) {
      this.headerObserver.observe(this.$refs.header)
    }
    this.mainObserver.observe(this.$refs.main)

    this.headerHeight = this.$refs.header?.clientHeight || 0
    this.setHeaderHeight(this.headerHeight)
    this.$refs.layout.style.setProperty('--c-bar-height', `${this.top}px`)
  },

  beforeDestroy: function () {
    if (this.headerObserver) this.headerObserver.disconnect()
    if (this.mainObserver) this.mainObserver.disconnect()
  },

  methods: {
    onHeaderResize(entries) {
      const height = entries[0].contentRect.height
      if (height > 0 && height !== this.headerHeight) {
        this.setHeaderHeight(height)
      }
    },

    setHeaderHeight(height) {
      this.headerHeight = height === 0 ? this.top : height + this.top + 2
      this.$refs.layout.style.setProperty('--c-header-height', `${this.headerHeight}px`)
      this.$emit('header', this.headerHeight)
    },

    onMainResize(entries) {
      this.mainHeight = entries[0].contentRect.height
      this.$refs.layout.style.setProperty('--c-main-height', `${this.mainHeight}px`)
    },

    onScroll(event) {
      const scrollTop = event.target.scrollTop
      console.warn('scrollTop=', scrollTop)
    }
  }
}
</script>

<style lang="scss" scoped>
/* default is 100% width, no sides */
.c-layout-container {
  --c-header-height: 0px;
  --c-side-min-width: 250px; /* need to set minimum because flex-item defaults to min-width: content */
  --c-side-max-width: 380px; /* 332 image + 2 * 24 padding + 0 border */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--v-sheet-base);
  width: 100%;
}
.c-scrollable {
  height: calc(var(--c-viewport-height) - var(--c-header-height));
  overflow-y: auto;
  overflow-x: hidden;
}
.c-fullheight {
  height: fit-content !important;
  overflow-y: auto;
}
.c-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

/* sections */
.c-layout-header {
  flex-grow: 0;
  width: 100%;
  height: fit-content;
}
.c-layout-main {
  width: 100%;
  transition: width 0.3s;

  .c-layout-middle {
    flex: 3 1 0;
    width: 100%;
  }

  .c-layout-side {
    flex: 1 3 0;
    width: clamp(var(--c-side-min-width), 25%, var(--c-side-max-width));
    min-width: var(--c-side-min-width);
    max-width: var(--c-side-max-width);
    padding: 0px;
    transition: width 0.3s;
  }
}

/* sacrifice the side columns for smaller widths */

@media only screen and (min-width: 0px) {
  .c-left-side {
    display: none;
  }
  .c-right-side {
    display: none;
  }
}
@media only screen and (min-width: 850px) {
  .c-right-side {
    display: flex;
  }
}
@media only screen and (min-width: 1080px) {
  .c-left-side {
    display: flex;
  }
}

/* fullscreen mode */
.c-layout-container.c-fullscreen {
  height: 100vh;
  height: 100dvh;

  .c-layout-header {
    display: none;
  }

  .c-layout-main {
    height: 100%;
    width: 100%;
    background-color: black;

    .c-layout-middle {
      height: 100%;
      width: 100%;
    }

    .c-layout-side {
      display: none;
    }
  }
}
</style>
