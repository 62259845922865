<template>
  <v-card class="c-card d-flex flex-column">
    <v-skeleton-loader
      v-bind="attrs"
      class="c-content-skeleton pb-5"
      :types="{
        'content-image': 'image',
        'content-metadata': 'button@2',
        'content-action-bar': 'avatar@5'
      }"
      type="list-item-avatar-three-line, content-image, content-metadata, content-action-bar"
    />
  </v-card>
</template>

<script>
export default {
  name: 'ContentCardSkeleton',

  components: {},

  data: function () {
    return {
      attrs: {
        elevation: 2
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  min-width: 275px;
  max-width: min(400px, 100%);
  border-radius: 16px;
}
</style>

<style lang="css">
.c-content-skeleton .v-skeleton-loader__content-image {
  margin: 0 16px;
}

.c-content-skeleton .v-skeleton-loader__content-action-bar {
  margin-top: 16px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.c-content-skeleton .v-skeleton-loader__content-metadata {
  margin-top: 8px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
