<template>
  <v-snackbar
    v-if="showCookieNotice"
    class="c-app-cookies c-snack"
    content-class="c-snack__content"
    app
    bottom
    color="primary"
    :dark="isDark"
    :light="!isDark"
    :timeout="-1"
    :value="showCookieNotice"
    width="100%"
  >
    {{ $t('app.cookies.notice') }}
    <template #action="{ attrs }">
      <v-btn
        color="secondary"
        small
        v-bind="attrs"
        @click="enableCookies"
      >
        {{ $t('app.cookies.enable') }}
        <v-icon
          dark
          right
        >
          mdi-checkbox-marked-circle
        </v-icon>
      </v-btn>
      <v-btn
        class="ml-4"
        color="secondary"
        outlined
        small
        v-bind="attrs"
        @click="disableCookies"
      >
        {{ $t('app.cookies.disable') }}
        <v-icon
          dark
          right
        >
          mdi-cancel
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AppCookies',

  data() {
    return {}
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    showCookieNotice() {
      return this.$feature('cookies') && this.permission === 'prompt'
    },

    permission() {
      return this.$store.state.appStore.cookies
    }
  },

  mounted: function () {},

  methods: {
    ...mapActions('appStore', ['enableCookies', 'disableCookies', 'getCookiePermission'])
  }
}
</script>

<style lang="scss" scoped>
.c-snack {
  :deep(.v-snack__wrapper) {
    padding-right: 8px;

    .c-snack__content {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
</style>
