<template>
    <v-snackbar
      v-model="snackbar"
      class="c-snackbar"
      color="red"
      text
      :timeout="timeout"
      top
    >
      <slot>
        {{ message }}
      </slot>
      <template #action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="onClose"
        >
          {{ $t('ui.close') }}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: 'ErrorToast',

  components: {},

  model: {
    prop: 'show',
    event: 'close'
  },

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      timeout: 5000, // 5 seconds
      snackbar: this.show
    }
  },

  watch: {
    show: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        this.snackbar = newShow
      }
    }
  },

  methods: {
    onClose() {
      this.snackbar = false
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-snackbar {
  & :deep(.v-snack__action button) {
    color: red !important;
  }
}
</style>
