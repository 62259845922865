// import client from '@client'
import client from '@/services/real'
import store from '@/store'

class ContentService {
  constructor() {
    this.type = 'sanity'
  }

  fetchContent = async ({ filter, collectionKey }) => {
    const items = await client.fetchContent({ filter, collectionKey })
    return items
  }

  fetchContentItem = async ({ id, slug }) => {
    const item = await client.fetchContentItem({ id, slug })
    return item
  }

  getContentHeaders = () => {
    return client.getContentHeaders()
  }

  fetchBook = async ({ bookId, bookPath }) => {
    const tenantKey = store.state.tenantStore.tenantKey

    try {
      const bookURL = bookId
        ? `/api/tenant/${tenantKey}/book/${bookId}`
        : `/api/tenant/${tenantKey}/book?path=${encodeURIComponent(bookPath)}`
      const response = await fetch(bookURL)
      if (response.ok) {
        const book = await response.json()
        return book
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      console.error('[contentService]: ', error)
      throw error
    }
  }

  async fetchModelConfig({ modelId, modelPath }) {
    const tenantKey = store.state.tenantStore.tenantKey

    try {
      const modelConfigURL = modelId
        ? `/api/tenant/${tenantKey}/model/${modelId}`
        : `/api/tenant/${tenantKey}/model?path=${encodeURIComponent(modelPath)}`

      const response = await fetch(modelConfigURL)
      if (response.ok) {
        return response.json()
      } else if (response.status === 404) {
        return {} // model.json is optional, so no error is returned
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      console.error('[contentService]: ', error)
      throw error
    }
  }
}

export default ContentService
export const contentService = new ContentService()
