<!-- eslint-disable vue/attribute-hyphenation -->
<!-- (React components use camelCase attributes) -->
<template>
  <div
    class="c-chatbot-player d-flex"
    :class="[
      isReady ? 'c-splitscreen' : '.c-initializing',
      isMobile
        ? 'c-portrait flex-column justify-start align-center'
        : 'c-landscape flex-row justify-center align-start'
    ]"
  >
    <div class="c-left d-flex justify-center align-center">
      <v-img
        class="c-avatar"
        :src="avatarImage"
        contain
      />
    </div>
    <div class="c-right g-skinny-scrollbars">
      <ChatbotChat
        class="c-chatbot"
        :corpus="corpus"
        @ready="isReady = true"
        @error:loading="$emit('error:loading')"
      />
    </div>
  </div>
</template>

<script>
import ChatbotChat from './components/ChatbotChat'
import mobileMixin from '@/mixins/mobileMixin'

export default {
  name: 'ChatbotPlayer',

  components: {
    ChatbotChat
  },

  mixins: [mobileMixin],

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      avatarImage: require('@/assets/images/avatars/melissa-avatar-with-name.png'),
      isReady: false
    }
  },

  computed: {
    corpus() {
      return this.content.params?.find((param) => param.name === 'corpus')?.value
    },

    isMobile() {
      return this.mobileMixin_isMobile
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    }
  }
}
</script>

<style lang="css" scoped>
.c-chatbot-player {
  --c-padding: 16px;
}
.c-chatbot-player.c-landscape {
  max-height: calc(100vh - var(--c-player-bar-height));
  max-height: calc(100dvh - var(--c-player-bar-height));
  overflow-y: hidden;
}
.c-chatbot-player .c-left {
  min-width: 200px;
  overflow-y: hidden;
}
.c-chatbot-player .c-right {
  min-width: 300px;
}
.c-chatbot-player .c-avatar {
  margin: var(--c-padding) auto;
  max-height: calc(100vh - var(--c-player-bar-height) - 2 * var(--c-padding));
  max-height: calc(100dvh - var(--c-player-bar-height) - 2 * var(--c-padding));
}

/* widescreen layout (before chatbot is ready) */
.c-chatbot-player.c-initializing {
  width: 100%;
  height: 100%;
}
.c-chatbot-player.c-initializing .c-left {
  height: 100%;
  width: 100%;
}
.c-chatbot-player.c-initializing .c-right {
  display: none;
}

/* splitscreen layout (after chatbot is ready) */
.c-chatbot-player.c-splitscreen {
}
.c-chatbot-player.c-splitscreen .c-left {
  width: 25%;
}
.c-chatbot-player.c-splitscreen.c-landscape .c-right {
  width: 75%;
  padding: 16px 5%;
  height: calc(100vh - var(--c-player-bar-height));
  overflow-y: auto;
}
.c-chatbot-player.c-splitscreen.c-landscape .c-right {
  width: 75%;
  padding: 16px 5%;
  height: calc(100vh - var(--c-player-bar-height));
}
.c-chatbot-player.c-splitscreen.c-portrait .c-right {
  width: 100%;
  padding: 16px 5%;
}
.c-chatbot-player.c-splitscreen .c-chatbot > div[role='complementary'] {
  max-width: 1200px !important;
}
</style>
