<template>
  <v-sheet
    ref="page"
    class="c-player-page"
    :class="{ 'c-fullscreen': isFullscreen }"
    color="background"
  >
    <!-- player chrome -->
    <PlayerBar
      v-model="showDetails"
      class="c-player-bar"
      :class="{ 'c-fullscreen': isFullscreen }"
      :item="item"
      @activate:canvas="isCanvasActivated = $event"
      @toggle:comments="showComments = !showComments"
      @close="onClose"
      @fullscreen="isFullscreen = $event"
      @height="top = $event"
      @share="shareItem($event)"
    />

    <PlayerDrawer
      class="c-drawer"
      :class="{ 'c-fullscreen': isFullscreen }"
      :context="context"
      :item="item"
      :open="showDetails"
      :params="params"
      @resizing="resizing = $event"
    />

    <CommentDrawer
      v-show="commentTarget"
      v-model="showComments"
      class="c-drawer"
      :class="{ 'c-fullscreen': isFullscreen }"
      :data-title="$t('tour.comments.drawer.title')"
      :data-intro="$t('tour.comments.drawer.intro')"
      :comments="comments"
      :target-id="commentTarget"
    />

    <!-- content area -->
    <CircularProgress
      v-if="isLoading"
      class="c-progress"
      :message="$t('message.fetchingContent')"
    />

    <template v-else>
      <div
        class="c-player-bar--spacer"
        :class="{ 'c-fullscreen': isFullscreen }"
      />

      <ContentLayout
        v-if="isFound"
        class="c-layout"
        :ad-free="isAdFree"
        :authors="authors"
        :fullscreen="isFullscreen"
        :params="params"
        :type="mediaType"
        :top="top"
      >
        <ContentPlayer
          :item="item"
          :fullscreen="isFullscreen"
          :params="params"
          @context="context = $event"
          @error:handled="onError(true, $event)"
          @error:loading="onError(false, $event)"
          @multi="isMultiPage = $event"
        />

        <div v-if="resizing" class="c-overlay"></div>

        <ContentCanvas
          class="c-canvas g-skinny-scrollbars"
          :class="{ 'c-fullscreen': isFullscreen }"
          :canvas="isCanvasActivated"
          :fullscreen="isFullscreen"
          :item="item"
        />
      </ContentLayout>

      <ErrorMessage
        v-else
        :title="$t('error.ContentNotFoundError.title')"
        :message="$t('error.ContentNotFoundError.message')"
      />
    </template>

    <ModalDialog
      v-model="showContentModal"
      confirm
      popup
      :title="$t('content.notice')"
      :submit-text="$t('ui.ok')"
    >
      {{ contentTypeDescription }}
    </ModalDialog>

    <ShareModal v-if="showSharingModal" v-model="showSharingModal" :entity="sharedEntity" />
  </v-sheet>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'
import ModalDialog from '@/components/base/ModalDialog'
import CommentDrawer from '@/components/comments/CommentDrawer'
import ContentCanvas from '@/components/content/ContentCanvas'
import ContentLayout from '@/components/content/ContentLayout'
import ContentPlayer from '@/components/content/ContentPlayer'
import ErrorMessage from '@/components/base/ErrorMessage'
import PlayerBar from '@/components/player/PlayerBar'
import PlayerDrawer from '@/components/player/PlayerDrawer'
import ShareModal from '@/components/base/ShareModal'

import exitMixin from '@/mixins/exitMixin.js'
import metaMixin from '@/mixins/metaMixin.js'
import mobileMixin from '@/mixins/mobileMixin.js'
import shareMixin from '@/mixins/shareMixin.js'
import { contentService } from '@/services/contentService'
import { mapActions, mapGetters } from 'vuex'
import { analyticsService } from '@/services/analyticsService.js'

class ParameterError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ParameterError'
  }
}

export default {
  name: 'PlayerPage',

  components: {
    CircularProgress,
    ModalDialog,
    CommentDrawer,
    ContentCanvas,
    ContentLayout,
    ContentPlayer,
    ErrorMessage,
    PlayerBar,
    PlayerDrawer,
    ShareModal
  },

  mixins: [exitMixin, metaMixin, mobileMixin, shareMixin],

  beforeRouteEnter: function (to, from, next) {
    console.debug('[beforeRouteEnter]: from', from)
    next((vm) => (vm.from = from))
  },

  beforeRouteUpdate: function (to, from, next) {
    console.debug('[beforeRouteUpdate]: from=', from)
    // following assignment doesn't work if :key is used
    // (but a watcher on $route can!)
    this.from = from
    next()
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      // content
      contentService: contentService,
      isLoading: true,
      isFound: false,
      isMultiPage: false,
      item: null,

      // canvas
      isCanvasActivated: false,

      // navigation
      from: null,

      // layout
      isFullscreen: false,
      prevScrollPosition: 0,
      top: 48,

      // ads
      showAds: true,

      // comments
      showComments: false,
      commentRefreshes: 0,

      // drawer
      context: null,
      showDetails: false, // null means initially open on mobile, closed on desktop
      resizing: false,

      // sharing
      showSharingModal: false,
      sharedEntity: {},
      showContentModal: false
    }
  },

  computed: {
    ...mapGetters('commentStore', ['getComments']),

    /* app context */

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* org context */

    orgName() {
      return this.$store.state.orgStore.orgName
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    /* layout */

    authors() {
      return this.item?.authors
    },

    comments() {
      return this.item?.id ? this.getComments(this.item.id) : []
    },

    commentTarget() {
      return this.item?.id || ''
    },

    isAdFree() {
      return (
        !this.item ||
        // any item can be ad free
        this.item.adFree === true ||
        // educational content is ad free
        this.item.contentTypeKeyword === 'detail-aid' ||
        this.item.contentTypeKeyword === 'learning-aid' ||
        this.item.contentTypeKeyword === 'cme' ||
        // promotional content is ad free
        this.item.contentTypeKeyword === 'brochure' ||
        this.item.contentTypeKeyword === 'promo'
      )
    },

    isArticle() {
      return this.mediaType === 'article'
    },

    contentTypeDescription() {
      return this.item?.contentTypeDescription?.[this.locale] || ''
    },

    mediaType() {
      return this.item?.mediaType
    },

    params() {
      return this.parseParams(this.item?.params || [])
    },

    showHeader() {
      return (
        (this.isReallyMobile || this.isArticle) &&
        !this.isFullscreen &&
        this.showAds &&
        !this.isAdFree
      )
    },

    showSide() {
      const show = !this.isReallyMobile && !this.isFullscreen && this.showAds && !this.isAdFree
      return show
    },

    /* meta context */

    eventProperties() {
      return {
        item_id: this.item.id,
        item_slug: this.item.slug,
        item_title: this.item.title,
        item_type: this.item.mediaType
      }
    },

    metaTags() {
      const title = this.item?.title || ''
      const description = this.item?.abstract || ''
      const thumbnail = this.item?.thumbnail || ''
      return {
        loaded: !this.isLoading,
        docTitle: `${title} | ${this.orgName}`,
        meta: [
          // OpenGraph
          { property: 'og:title', content: title },
          { property: 'og:image', content: thumbnail },
          { property: 'og:description', content: description },
          { property: 'og:site_name', content: this.orgName },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: window.location.href },

          // Google / Schema.org
          { itemprop: 'name', content: title },
          { itemprop: 'image', content: thumbnail },
          { itemprop: 'description', content: description }
        ]
      }
    }
  },

  watch: {
    id: {
      immediate: true,
      handler: async function (toId, _fromId) {
        const id = this.shareMixin_extractId(toId)
        const slug = null
        this.fetchAll({ id, slug })
      }
    },

    showComments: {
      immediate: false,
      handler: function (newShow, _oldShow) {
        if (newShow) this.commentRefreshes++
        // occasionally refresh to get comments by others
        if (this.commentRefreshes > 2 && this.item?.id) {
          this.commentRefreshes = 0
          this.fetchComments({ targetId: this.item.id, refresh: true })
        }
      }
    },

    $route: {
      immediate: false,
      handler: function (to, from) {
        this.from = from
      }
    }
  },

  mounted: async function () {
    this.$refs.page.$el.style.setProperty('--c-player-bar-height', `${this.top}px`)
  },

  activated: async function () {},

  deactivated: function () {},

  methods: {
    ...mapActions('adStore', ['clearAds', 'fetchAds']),
    ...mapActions('commentStore', ['fetchComments']),
    ...mapActions('contentStore', ['fetchItem']),

    resetData() {
      // content
      this.context = null
      this.isLoading = true
      this.isFound = false
      this.item = null

      // scrolling
      this.prevScrollPosition = 0
    },

    async fetchAll({ id, slug }) {
      try {
        this.clearAds()

        // fetch item
        await this.retrieveItem({ id, slug })

        if (this.item) {
          this.showContentModal = !!this.item.doNotShare

          // fetch ads
          this.fetchItemAds()

          // fetch comments
          this.fetchComments({ targetId: this.item.id, refresh: true })

          this.$emit('loaded')
        }
      } catch (error) {
        console.error('[PlayerPage]:', error)
        throw error
      }
    },

    async retrieveItem({ id, slug }) {
      try {
        this.isLoading = true
        this.isFound = false

        if (id || slug) {
          this.item = await this.fetchItem({ id, slug })
          if (this.item) {
            this.isFound = true
          }

          // capture view event
          analyticsService.capture('content_view', this.eventProperties)

          return this.item
        } else {
          throw new ParameterError(`Error: item '${id || slug}' not found.`)
        }
      } catch (error) {
        console.error('[PlayerPage]:', error)
        throw error
      } finally {
        this.isLoading = false
      }
    },

    async fetchItemAds() {
      try {
        this.clearAds()
        if (!this.isAdFree) {
          const itemKeywords = this.item.adWords || []
          const topicKeywords = this.item.topicKeywords || []
          const portalKeywords = [this.portalKey]
          const keywords = this.item?.hasExclusiveAds
            ? [...itemKeywords, '__exclusive__']
            : [...itemKeywords, ...topicKeywords, ...portalKeywords]

          this.fetchAds({
            locale: this.locale,
            keywords
          })
        }
      } catch (error) {
        console.error('[PlayerPage]:', error)
      }
    },

    parseParams(params) {
      // raw params is an array of strings: key1:value1, key2:value2
      const paramsArray = params.map((param) => {
        const key = param.name
        const stringValue = param.value.toLowerCase().trim()
        const value = stringValue === 'false' ? false : stringValue === 'true' ? true : stringValue
        return { [key]: value }
      })

      const paramsObject = Object.assign({}, ...paramsArray)

      console.debug('[PlayerPage]: params=', paramsObject)
      return paramsObject
    },

    /* events */

    onClose() {
      /*
       * There are two events that initiate closing a player:
       * 1)The Player emits an "error" event.
       * 2)The user selects the "close" from the PlayerBar
       *
       */
      console.debug('[PlayerPage]: Closing the player.')
      analyticsService.capture('content_close', this.eventProperties)

      // go back...or forward (i.e. a fake "go back")...to whence you came
      const exitTo = this.isMultiPage ? this.from : null
      this.exitMixin_close({ from: this.from, to: exitTo })
    },

    async onError(isHandled, error) {
      console.debug('[PlayerPage]: Closing the player due to an error.')
      if (!isHandled) await this.showAlert(error)
      this.onClose()
    },

    /* actions */

    async shareItem() {
      this.sharedEntity = await this.shareMixin_shareItem(this.item)
      this.showSharingModal = !!this.sharedEntity
    },

    async showAlert(error) {
      const result = await this.$alert({
        icon: 'error',
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        footer: error,
        confirmButtonText: this.$t(`ui.close`)
      })
      return result.isConfirmed ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
/* player chrome */

/* Layers (z-index):
 *  -1: Drawing Canvas (inactive)
 *   0: Player Viewport
 *   1: Player Bar
 *   1: Drawing Canvas (active)
 *   1: Overlay (drawer resizing)
 *   2: Player Drawer
 *   ?: Controls
 */

.c-player-page {
  --c-player-bar-height: 48px;
  background-color: var(--v-background-base);
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
}
.c-player-bar {
  z-index: 1;

  &.c-fullscreen {
    display: none;
  }
}
.c-player-bar--spacer {
  padding-top: var(--c-player-bar-height);

  &.c-fullscreen {
    padding-top: 0px;
  }
}
.c-drawer {
  top: var(--c-player-bar-height) !important;
  height: calc(var(--c-viewport-height) - var(--c-player-bar-height)) !important;
  overflow-y: auto;

  &.c-fullscreen {
    display: none;
    visibility: hidden;
  }
}

/* content area */
.c-layout {
  position: relative;
  z-index: 0;
}
.c-progress {
  padding-top: var(--c-player-bar-height);
  height: calc(var(--c-viewport-height));
  overflow: hidden;
}
.c-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.c-canvas {
  min-height: 100%;
  width: 100%;

  &.c-fullscreen {
    height: 100vh;
    height: 100dvh;
    width: 100%;
    background-color: black;
  }
}
</style>
