<template>
  <component
    :is="player"
    :content="item"
    :fullscreen="fullscreen"
    :params="params"
    @context="$emit('context', $event)"
    @error:handled="$emit('@error:handled', $event)"
    @error:loading="$emit('@error:loading', $event)"
  />
</template>

<script>
import players from '@/players/playerRegistry.json'

export default {
  name: 'ContentPlayer',

  components: {},

  props: {
    item: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {}
  },

  computed: {
    player() {
      const playerComponent =
        players.find((player) => player.mediaType === this.item?.mediaType)?.player || 'WebViewer'
      return () =>
        import(/* webpackChunkName: "[request]" */ `@/players/${playerComponent}/index.js`)
    },

    isMultiPage() {
      return !!players.find((player) => player.mediaType === this.item?.mediaType)?.isMultiPage
    }
  },

  watch: {
    isMultiPage: {
      immediate: true,
      handler: function (newVal, _oldVal) {
        this.$emit('multi', newVal)
      }
    }
  },

  mounted: function () {},

  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
