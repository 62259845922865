import store from '@/store'

export const fetchComments = async (targetId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/comments?target=${targetId}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[commentService]:', error)
    throw error
  }
}

export const fetchReplies = async (parentId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/comments?parent=${parentId}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[commentService]:', error)
    throw error
  }
}

export const addComment = async (comment) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...comment })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/comment`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[commentService]:', error)
    throw error
  }
}

export const modifyComment = async (comment) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const commentId = comment.id

  try {
    const request = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...comment })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/comment/${commentId}`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[commentService]:', error)
    throw error
  }
}

export const removeComment = async (comment) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const commentId = comment.id

  try {
    const request = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...comment })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/comment/${commentId}`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[commentService]:', error)
    throw error
  }
}
