<template>
  <DrawCanvas
    class="c-canvas"
    :class="{'c-active': isCanvasActivated}"
    :activated="isCanvasActivated"
    :type="item.mediaType"
    @capture="capture"
    @download="download"
  />
</template>

<script>
import DrawCanvas from '@/components/draw/DrawCanvas'
import { toPng } from 'html-to-image'

export default {
  name: 'ContentCanvas',

  components: {
    DrawCanvas
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    canvas: {
      type: Boolean,
      required: false,
      default: false
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // canvas
      canvasHeight: 0,
      canvasWidth: 0,
      isCanvasActivated: false,
      wasCanvasActivated: false,
      offsetX: 0,
      offsetY: 0,

      // content
      context: {},

      // preview
      previewSrc: '',
      showPreview: false
    }
  },

  computed: {
    isFullscreen() {
      return this.fullscreen
    }
  },

  watch: {
    canvas: {
      handler: function (newValue, _oldValue) {
        this.isCanvasActivated = newValue
      }
    },

    isFullscreen: {
      immediate: false,
      handler: function (newFullscreen, _oldFullscreen) {
        if (newFullscreen) {
          this.wasCanvasActivated = this.isCanvasActivated
          this.isCanvasActivated = false
        } else {
          this.isCanvasActivated = this.wasCanvasActivated
        }
      }
    }
  },

  mounted: function () {
    this.isCanvasActivated = this.canvas
  },

  methods: {
    async capture() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.createElement('video')

      /* capture screen */
      try {
        // start RTC video stream
        const captureStream = await navigator.mediaDevices.getDisplayMedia()
        video.srcObject = captureStream

        // capture an data image of the video frame
        context.drawImage(video, 0, 0, window.width, window.height)
        const dataURI = canvas.toDataURL('image/png')
        console.log('dataURI=', dataURI)

        // create a blob from the data image
        const dataBlob = await (await fetch(dataURI)).blob()
        console.log('blob=', dataBlob)

        // close the RTC video stream
        captureStream.getTracks().forEach((track) => track.stop())

        // paste the blob into the clipboard and download it
        navigator.clipboard.write([new window.ClipboardItem({ 'image/png': dataBlob })])
        window.saveAs(dataBlob, 'lumenii.png')

        // render the img in a preview popup
        this.previewSrc = dataURI
        this.showPreview = true
      } catch (error) {
        console.error('[DrawCanvas]: Error capturing screen.', error)
      }
    },

    async download() {
      // generate a DOM image
      try {
        const dataURI = await toPng(this.$refs.ContentCanvas)
        this.previewSrc = dataURI
      } catch (error) {
        console.error('[ContentCanvas]: Oops, something went wrong!', error)
      }

      // render the img in a preview popup
      this.showPreview = true

      // download image
      const link = document.createElement('a')
      link.download = 'lumenii.png'
      link.href = this.previewSrc
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.c-canvas {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  min-height: 100%;

  &.c-active {
    display: block;
    z-index: 1;
  }
}
</style>
