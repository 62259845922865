<template>
  <div class="c-image-viewer d-flex">
    <v-img
      class="c-image"
      :src="src"
      contain
    />
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',

  components: {},

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      src: this.content.contentURL
    }
  }
}
</script>

<style lang="css" scoped>
.c-image-viewer {
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  margin: auto;
}
</style>
