<template>
  <v-card class="c-thread-card" flat>
    <v-alert
      v-model="showError"
      class="ma-0 mb-2 rounded-xl"
      dismissible
      type="error"
    >
      {{ errorMessage }}
    </v-alert>
    <v-card
      v-show="showForm"
      class="c-card mb-2 pa-2 rounded-xl"
      outlined
    >
      <CommentForm
        :rich=rich
        @cancel="onCancel()"
        @submit="onSubmit($event)"
      />
    </v-card>
    <div
      v-for="(comment, index) in comments"
      :key="comment.id"
      class="c-outer-thread"
    >
      <div v-if="debug"> {{ comment.id }} </div>
      <CommentCard
        v-if="comment"
        ref="activeCard"
        v-model="comment.body"
        class="mb-2"
        :comment="comment"
        :rich=rich
        @replies="onReplies(comment, index)"
        @reply="onReply(comment, index)"
        @delete="onDelete(comment)"
        @update="onUpdate({ body: $event, comment })"
      />
      <CommentThread
        v-if="activeThread === comment.id"
        :key="comment.id"
        class="c-inner-thread"
        :comments="comment.replies"
        :parent-id="comment.id"
        :rich="rich"
        :show-form="activeForm === comment.id"
        :show-replies="activeThread === comment.id"
        :target-id="targetId"
        @close="activeForm = 0"
      />
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommentCard from '@/components/comments/CommentCard'
import CommentForm from '@/components/comments/CommentForm'

export default {
  name: 'CommentThread',

  components: {
    CommentCard,
    CommentForm
  },

  model: {
    prop: 'showForm',
    event: 'close'
  },

  props: {
    comments: {
      type: Array,
      required: false,
      default: () => []
    },

    showForm: {
      type: Boolean,
      required: false,
      default: false
    },

    rich: {
      type: Boolean,
      required: false,
      default: true
    },

    parentId: {
      type: String,
      required: false,
      default: '0'
    },

    targetId: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      // threadComments: [],
      activeForm: 0,
      activeThread: 0,
      showError: false,
      errorMessage: '',
      debug: false
    }
  },

  computed: {
    ...mapGetters('commentStore', ['getCommentsByParent']),

    localComments() {
      const targetId = this.targetId
      const parentId = this.parentId
      return this.getCommentsByParent({ targetId, parentId })
    }
  },

  watch: {
    comments: {
      immediate: true,
      handler: function (_newC, _oldC) {
        // this.threadComments = [...newC]
      }
    }
  },

  methods: {
    ...mapActions('commentStore', ['addComment', 'modifyComment', 'removeComment']),

    async generateComment(body) {
      try {
        const basicComment = {
          author: {
            name: this.$auth.getUserDisplayName(),
            email: this.$auth.getUserEmail(),
            avatar: this.$auth.getUserAvatar()
          },
          body: body,
          parentId: this.parentId,
          targetId: this.targetId
        }

        const addedComment = await this.addComment({ comment: basicComment })
        return addedComment
      } catch (error) {
        this.errorMessage = this.$t('comment.errors.add') + this.$t('comment.errors.again')
        this.showError = true
        console.error('[CommentThread]: Unable to add comment.', error)
      }
    },

    async replaceCommentBody({ comment, body }) {
      const updatedComment = {
        ...comment,
        body
      }

      try {
        await this.modifyComment({ comment: updatedComment })
      } catch (error) {
        this.errorMessage = this.$t('comment.errors.update') + this.$t('comment.errors.again')
        this.showError = true
        console.error('[CommentThread]: Unable to update comment.', error)
      }
    },

    async deleteComment(comment) {
      try {
        await this.removeComment({ comment })
      } catch (error) {
        this.errorMessage = this.$t('comment.errors.remove') + this.$t('comment.errors.again')
        this.showError = true
        console.error('[CommentThread]: Unable to remove comment.', error)
      }
    },

    onDelete(comment) {
      this.deleteComment(comment)
    },

    onReplies(comment, _index) {
      this.activeThread = this.activeThread === comment.id ? '0' : comment.id
      // this.scrollIntoView(index)
    },

    onReply(comment, index) {
      this.activeForm = this.activeForm === comment.id ? '0' : comment.id
      if (this.activeForm) this.activeThread = comment.id
      this.scrollIntoView(index)
    },

    onSubmit(body) {
      this.generateComment(body)
      this.$emit('close', false)
    },

    onUpdate({ comment, body }) {
      this.replaceCommentBody({ comment, body })
      this.$emit('close', false)
    },

    onCancel() {
      this.$emit('close', false)
    },

    scrollIntoView(index) {
      this.$nextTick(() => {
        this.$refs.activeCard[index].$el.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth'
        })
      })
    }
  }
}
</script>

<style lang="css" scoped>
.c-thread-card {
  background-color: transparent;
}
.c-outer-thread {
}
.c-inner-thread {
  border-left: solid 1px grey;
  padding-left: 16px;
}
</style>
