<template>
  <div
    v-if="mobileSafariPWA"
    class="c-pull"
    :class="{ 'c-visible': showPullToRefresh }"
  >
    <v-progress-circular
      color="primary"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: 'PullToRefresh',

  components: {},

  data: function () {
    return {
      touchstartY: 0,
      showPullToRefresh: false,
      mobileSafariPWA: false,
      threshold: 40
    }
  },

  mounted: function () {
    this.mobileSafariPWA = window.navigator.standalone

    document.addEventListener('touchstart', this.onStart)
    document.addEventListener('touchmove', this.onMove)
    document.addEventListener('touchend', this.onEnd)
  },

  beforeDestriy: function () {
    document.removeEventListener('touchstart', this.onStart)
    document.removeEventListener('touchmove', this.onMove)
    document.removeEventListener('touchend', this.onEnd)
  },

  methods: {
    onStart(e) {
      this.touchstartY = e.touches[0].clientY
    },

    onMove(e) {
      const touchY = e.touches[0].clientY
      const deltaY = touchY - this.touchstartY

      if (deltaY > this.threshold && window.scrollY <= 0) {
        this.showPullToRefresh = true
        e.preventDefault() // disable scrolling
      } else {
        this.showPullToRefresh = false
      }
    },

    onEnd(_e) {
      if (this.showPullToRefresh) {
        this.showPullToRefresh = false
        console.debug('[PullToRefres]: reload!')
        // window.location.reload()
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-pull {
  position: fixed;
  top: -48px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.7s ease-in-out;
  z-index: 2;
}

.c-pull.c-visible {
  top: 0px;
}
</style>
