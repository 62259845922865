export default {
  methods: {
    /*
     * There are three "close" scenarios:
     * 1)arrived via a shared link
     * 2)arrived via a bookmark (i.e. first route visited)
     * 3)arrived via a navigation
     *
     * Logic overview:
     * - 'from' should be the app route visited before the player route (if any)
     * - 'multiPage' players use iFrames where "goBack" routing navigates the content itself
     * - when 'from' is null, authenticated users are sent to "home", whereas others will exit the app
     * - when 'to' is null, exiting maps to a "goBack", otherwise, a fake back via a "goTo"
     * FIXME: ideally, we should send an "exit" message to the iframe
     */

    exitMixin_close({ from, to }) {
      /*
       * "first route" as a result of a bookmark, email campaign,
       * shared link or cut'n'paste into the address bar (hence no from)
       *
       */
      const isFirstRoute = !from?.name
      console.debug('[exitMixin]: history=', window.history.length)

      if (isFirstRoute) {
        // send authenticated users "Home" but others simply go back
        this.$auth.isAuthenticated()
          ? this.$router.push({ name: 'home' })
          : window.history.length
          ? this.$router.back()
          : this.exitMixin_exit()
      } else {
        // go ahead and backup?
        to ? this.$router.push(to) : this.$router.back()
      }
    },

    exitMixin_close2({ from, to }) {
      const isFirstRoute = !from?.name

      if (isFirstRoute) {
        // send authenticated users "Home" but others must exit the app
        this.$auth.isAuthenticated() ? this.$router.push({ name: 'home' }) : this.exitMixin_exit()
      } else {
        // go ahead and backup?
        to ? this.$router.push(to) : this.$router.back()
      }
    },

    exitMixin_back() {
      this.$router.back()
    },

    exitMixin_exit() {
      // close the window (but first, ensure the app owns the window)
      const url = `${window.location.origin}/splash?notice=exit`
      const w = window.open(url, '_self')
      w.close()
      // return false if the window didn't close
      return false
    }
  }
}
