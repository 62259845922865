<template>
  <ResizableDrawer
    color="background"
    hide-overlay
    left
    :value="open"
    width="400"
    @resizing="$emit('resizing', $event)"
  >
    <div class="c-container pa-3">
      <!-- ad -->
      <AdSpot
        v-if="showAds"
        v-model="showAds"
        class="c-ads mb-4"
        :max="1"
        stack
        zone="drawer"
      />

      <!-- details -->
      <ContentDetails
        v-if="item"
        :data-title="$t('tour.playerPage.details.title')"
        :data-intro="$t('tour.playerPage.details.intro')"
        :item="item"
        :body="body"
      />

      <!-- assistance on/off switches -->
      <v-card v-if="hasGuidance || hasCorpus" class="c-assistance mx-auto mt-4" tile>
        <v-list rounded>
          <v-subheader>
            {{ $t('content.assistance.title').toUpperCase() }}
          </v-subheader>

          <!-- chatbot -->
          <v-list-item v-if="hasCorpus" class="my-0">
            <v-list-item-avatar>
              <v-img class="c-avatar" :src="avatarImage" contain />
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
              <v-switch
                v-model="showChatbot"
                class="c-switch"
                color="primary"
                dense
                :label="$t('content.assistance.askMelissa')"
                :ripple="false"
              />
            </v-list-item-content>
          </v-list-item>

          <!-- guidance -->
          <v-list-item v-if="hasGuidance" class="my-0">
            <v-list-item-icon class="mr-4">
              <v-icon size="40" large> mdi-compass </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <v-switch
                v-model="showGuidance"
                class="c-switch"
                color="primary"
                dense
                :label="$t('content.assistance.guideMe')"
                :ripple="false"
                @change="$bus.$emit('select:guidance', showGuidance)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <!-- quizzes -->
      <div v-if="hasQuizzes">
        <QuizCard
          v-for="quiz in quizzes"
          :key="quiz.id"
          class="c-quiz-card pa-4"
          :quiz="quiz"
        />
      </div>

      <!-- scenes and animations -->
      <v-card v-if="hasScenes" class="c-scenes mx-auto mt-4" tile>
        <SceneList
          v-if="hasMoreThanOneScene"
          v-model="selectedSceneId"
          class="mb-4"
          :scenes="scenes"
          @select="selectScene($event)"
          @sync="syncScene($event)"
        />
        <AnimationList
          v-if="hasAnimations"
          v-model="selectedAnimationIds"
          :animations="animations"
          @select="selectAnimation($event)"
          @sync="syncAnimations($event)"
        />
      </v-card>

      <!-- chatbot -->
      <div v-if="hasCorpus" class="c-chatbot flex-column align-start justify-start mt-4">
        <ChatbotChat
          v-show="showChatbot"
          ref="chatbot"
          :corpus="corpus"
        />
      </div>
    </div>
  </ResizableDrawer>
</template>

<script>
import AdSpot from '@/components/ad/AdSpot'
import AnimationList from '@/players/ModelPlayer/components/AnimationList'
import ChatbotChat from '@/players/ChatbotPlayer/components/ChatbotChat'
import ContentDetails from '@/components/content/ContentDetails'
import QuizCard from '@/components/quiz/QuizCard'
import ResizableDrawer from '@/components/base/ResizableDrawer'
import SceneList from '@/players/ModelPlayer/components/SceneList'

export default {
  name: 'PlayerDrawer',

  components: {
    AdSpot,
    AnimationList,
    ChatbotChat,
    ContentDetails,
    QuizCard,
    ResizableDrawer,
    SceneList
  },

  model: {
    prop: 'open',
    event: 'toggled'
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: () => {}
    },

    adFree: {
      type: Boolean,
      required: false,
      default: false
    },

    context: {
      type: Object,
      required: false,
      default: () => {}
    },

    open: {
      type: Boolean,
      required: true
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      avatarImage: require('@/assets/images/avatars/melissa-avatar-with-name.png'),
      // widgets
      showChatbot: true,
      showGuidance: false,
      showQuizzes: true,
      quizzes: [],
      body: [],
      // 3D models
      scenes: [],
      selectedSceneId: '',
      selectedAnimationIds: [],
      // ads
      showAds: !this.adFree
    }
  },

  computed: {
    /* context */

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* corpus */

    corpus() {
      // string
      return this.params.corpus
    },

    hasCorpus() {
      return !!this.corpus
    },

    /* guidance */

    guidance() {
      // boolean
      return this.params.guidance || false
    },

    hasGuidance() {
      return this.guidance
    },

    /* quizzes */

    hasQuizzes() {
      return this.quizzes.length > 0
    },

    /* animations & scenes */

    animations() {
      return this.scenes[this.selectedSceneId]?.[this.locale]?.animations || []
    },

    hasAnimations() {
      return this.hasScenes && this.animations.length > 0
    },

    hasScenes() {
      return this.scenes.filter((scene) => scene.id).length > 0
    },

    hasMoreThanOneScene() {
      return this.scenes.filter((scene) => scene.id).length > 1
    }
  },

  watch: {
    context: {
      immediate: false, // context is resolved after mounting
      handler: function (newContext, _oldContext) {
        // 3D model scenes and animations
        this.scenes = newContext.scenes || []
        this.animations = newContext.animations || []
        this.selectedSceneId = newContext.sceneId || ''
        // quizzes
        this.quizzes = newContext.quizzes || []
        // body (portable text)
        this.body = newContext.body || []
      }
    }
  },

  mounted: function () {
    const elements = document.getElementsByClassName('v-navigation-drawer__content')
    elements[0].classList.add('g-skinny-scrollbars')
    // listen to the SceneSelector / AnimationSelector
    this.$bus.$on('sync:selector', ({ selectedSceneId, selectedAnimationIds }) => {
      if (selectedSceneId) {
        this.selectedSceneId = selectedSceneId
      }
      if (selectedAnimationIds) {
        this.selectedAnimationIds = [...selectedAnimationIds]
      }
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:selector')
  },

  methods: {
    selectScene(_sceneId) {
      // clear all aninmations
      this.selectedAnimationIds = []

      // notify other components
      // this.$bus.$emit('select:scene', sceneId)
    },

    syncScene(selectedSceneId) {
      // notify the SceneSelector
      this.$bus.$emit('sync:drawer', {
        selectedSceneId,
        selectedAnimationIds: []
      })
    },

    selectAnimation(_animationId) {
      // notify other components
      // this.$bus.$emit('select:animation', animationId)
    },

    syncAnimations(selectedAnimationIds) {
      // notify the AnimationSelector
      this.$bus.$emit('sync:drawer', { selectedAnimationIds })
    },

    clearChatHistory() {
      this.$refs.chatbot.clearChatHistory()
    }
  }
}
</script>

<style lang="css" scoped>
.c-container p {
  font-size: 1.25rem;
}

.v-list-item {
  margin-bottom: 16px;
}

/* chatbot */

.c-chatbot {
  width: 100%;
  height: 100%;
}
.c-chatbot .c-avatar {
  width: 128px;
  height: 128px;
  margin: auto;
}

/* scenes */
.c-list-item--active {
  background-color: var(--v-accent-base);
}

.c-scenes .c-header {
  font-weight: 600;
}

.c-scenes .c-subheader {
  color: var(--v-accent-base) !important;
  font-style: italic;
}
</style>
