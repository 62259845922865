<template>
  <div class="c-web-viewer c-container d-flex align-center justify-center">
    <iframe
      :key="src"
      ref="iframe"
      class="c-iframe"
      allowfullscreen
      :src="src"
      :title="title"
    />
  </div>
</template>

<script>
import playerMixin from '@/mixins/playerMixin'

export default {
  name: 'WebViewer',

  components: {},

  mixins: [playerMixin],

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      src: this.content.contentURL,
      title: this.content.title || 'Untitled'
    }
  }
}
</script>

<style lang="scss" scoped>
.c-container {
  position: relative;
  height: 0;
  padding-top: 75%;
  overflow: hidden;

  .c-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #0c0f0e;
  }
}

@media (orientation: landscape) {
  .c-container {
    padding-top: 56.25%; /* 9 / 16 */
  }
}
@media (orientation: portrait) {
  .c-container {
    padding-top: 75%; /* 3 / 4 */
  }
}
</style>
