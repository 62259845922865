<template>
  <WidgetWrapper
    class="c-people-widget"
    :closeable="false"
    :title="title"
  >
    <div
      v-for="person in people"
      :key="person.id"
      class="c-person d-flex flex-wrap mb-2"
    >
      <PersonBanner
        v-if="banner"
        class="c-person-banner"
        :person="person"
        :size="72"
        :suffixes="suffixes"
      />
      <PersonCard
        v-else
        class="c-person-card"
        :person="person"
      />
    </div>
  </WidgetWrapper>
</template>

<script>
import PersonBanner from '@/components/person/PersonBanner'
import PersonCard from '@/components/person/PersonCard'
import WidgetWrapper from '@/components/base/WidgetWrapper'

export default {
  name: 'PeopleWidget',

  components: {
    PersonBanner,
    PersonCard,
    WidgetWrapper
  },

  props: {
    people: {
      type: Array,
      required: true
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    banner: {
      type: Boolean,
      required: false,
      default: true
    },

    suffixes: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  }
}
</script>

<style lang="css" scoped>
.c-people-widget {
  max-width: 400px;
}
.c-person-card {
  flex: 1 1 0;
}
.c-person-banner {
  width: 100%;
}
</style>
