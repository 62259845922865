import store from '@/store'

export const fetchJournals = async () => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/journals`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[journalService]:', error)
    throw error
  }
}

export const fetchJournalById = async (journalId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/journal/${journalId}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error(`[journalService]: Unable to retrieve journal '${journalId}'. ${error}`)
    throw error
  }
}

export const fetchJournalBySlug = async (journalSlug) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/journal/slug/${journalSlug}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error(`[journalService]: Unable to retrieve journal '${journalSlug}'. ${error}`)
    throw error
  }
}
