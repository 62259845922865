import localForage from 'localforage'
import { APP_KEY } from '@/config/appConfig.js'
import { memoryStorage } from './memory.js'

async function isLocalForage() {
  try {
    const testKey = 'Lumenii:test'
    await localForage.setItem(testKey, 'success')
    await localForage.getItem(testKey)
    await localForage.removeItem(testKey)
    return true
  } catch (e) {
    console.warn('[store]: IndexDB not available.  Using memory instead.')
    return false
  }
}

// if localStorage is not available, fallback to memory
const storage = isLocalForage() ? localForage : memoryStorage

// Using config()
localForage.config({
  driver: [localForage.INDEXEDDB],
  name: APP_KEY,
  storeName: 'Stores'
})

export async function getItem(key, defaultValue = null) {
  try {
    return storage.getItem(key)
  } catch (e) {
    console.error(
      `[store]: Unable to retrieve database item with key=${key}.  ${e.message}.  Using default value.`
    )
    return defaultValue
  }
}

export async function setItem(key, value) {
  try {
    storage.setItem(key, value)
    return true
  } catch (e) {
    console.error(`[store]: Unable to set database item with key=${key}.  ${e.message}.`)
    return false
  }
}

export async function removeItem(key) {
  try {
    storage.removeItem(key)
    return true
  } catch (e) {
    console.error(`[store]: Unable to remove database item with key=${key}.  ${e.message}.`)
    return false
  }
}
